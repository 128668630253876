import React, { MouseEvent as MEvent, TouchEvent as TEvent } from "react";
import { HatScreenImage } from "hat-common";

type ScreenDisplayMouseEvent = (mx: number, my: number) => void;

interface ScreenDisplayProps {
  image: HatScreenImage;
  grid?: boolean;
  bkColor?: string;
  fgColor?: string;
  imageMouseDown?: ScreenDisplayMouseEvent;
  imageMouseUp?: ScreenDisplayMouseEvent;
  imageMouseMove?: ScreenDisplayMouseEvent;
}

class ScreenDisplay extends React.Component<ScreenDisplayProps> {
  private _canvasRef = React.createRef<HTMLCanvasElement>();
  private _pixelSize = 0;
  private _trackedTouchId: number | null = null;

  componentDidMount() {
    this.prepareCanvasLayout();
    this.imageToCanvas();
  }

  componentDidUpdate() {
    this.imageToCanvas();
  }

  get bkColor(): string {
    return this.props.bkColor || "black";
  }

  get fgColor(): string {
    return this.props.fgColor || "white";
  }

  render() {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <canvas
          ref={this._canvasRef}
          onMouseDown={this.canvasMouseDown.bind(this)}
          onMouseUp={this.canvasMouseUp.bind(this)}
          onMouseMove={this.canvasMouseMove.bind(this)}
          onTouchStart={this.canvasTouchStart.bind(this)}
          onTouchMove={this.canvasTouchChange.bind(this)}
          onTouchEnd={this.canvasTouchEnd.bind(this)}
        ></canvas>
      </div>
    );
  }

  get pixelSize(): number {
    return this._pixelSize;
  }

  private prepareCanvasLayout() {
    const canvas = this._canvasRef.current;
    if (!canvas) {
      return;
    }

    const bounds = {
      height: canvas.parentElement!.clientHeight,
      width: canvas.parentElement!.clientWidth,
    };
    this._pixelSize = Math.floor(
      Math.min(
        (bounds.width - 8) / this.props.image.width,
        bounds.height / this.props.image.height
      )
    );

    canvas.width =
      this._pixelSize * this.props.image.width + (this.props.grid ? 1 : 0);
    canvas.height =
      this._pixelSize * this.props.image.height + (this.props.grid ? 1 : 0);
  }

  public getDrawContext() {
    const canvas = this._canvasRef.current;
    if (!canvas) {
      return null;
    }

    return canvas.getContext("2d")!;
  }

  public imageToCanvas(): void {
    const drawContext = this.getDrawContext();
    if (!drawContext) {
      return;
    }

    // Clear
    const bounds = drawContext.canvas.getBoundingClientRect();
    drawContext.fillStyle = "white";
    drawContext.fillRect(0, 0, bounds.width, bounds.height);

    const imWidth = this.props.image.width;
    const imHeight = this.props.image.height;
    const gridWidth = this.props.grid ? 1 : 0;
    const scheduleSize = this._pixelSize - gridWidth;
    const bkColor = this.bkColor;
    const fgColor = this.fgColor;

    for (let y = 0; y < imHeight; y++) {
      for (let x = 0; x < imWidth; x++) {
        drawContext.fillStyle = this.props.image.get(x, y) ? fgColor : bkColor;
        drawContext.fillRect(
          gridWidth + x * this._pixelSize,
          gridWidth + y * this._pixelSize,
          scheduleSize,
          scheduleSize
        );
      }
    }
  }

  public clientToImageCoords(
    clientX: number,
    clientY: number
  ): [x: number, y: number] {
    const canvasRect = this._canvasRef.current?.getBoundingClientRect();
    if (!canvasRect) {
      return [0, 0];
    }

    const px = Math.floor((clientX - canvasRect.x) / this._pixelSize);
    const py = Math.floor((clientY - canvasRect.y) / this._pixelSize);

    return [px, py];
  }

  private trackedTouch(evt: TEvent<HTMLCanvasElement>): React.Touch | null {
    if (this._trackedTouchId === null) {
      return null;
    }

    for (var i = 0; i < evt.changedTouches.length; i++) {
      const candidate = evt.changedTouches.item(i);
      if (candidate.identifier === this._trackedTouchId) {
        return candidate;
      }
    }

    return null;
  }

  private canvasMouseDown(evt: MEvent<HTMLCanvasElement>) {
    if (this._trackedTouchId !== null) {
      return;
    }

    const [px, py] = this.clientToImageCoords(evt.clientX, evt.clientY);
    if (this.props.imageMouseDown) {
      this.props.imageMouseDown(px, py);
    }
  }

  private canvasMouseMove(evt: MEvent<HTMLCanvasElement>) {
    if (this._trackedTouchId !== null) {
      return;
    }

    const [px, py] = this.clientToImageCoords(evt.clientX, evt.clientY);
    if (this.props.imageMouseMove) {
      this.props.imageMouseMove(px, py);
    }
  }

  private canvasMouseUp(evt: MEvent<HTMLCanvasElement>) {
    if (this._trackedTouchId !== null) {
      return;
    }

    const [px, py] = this.clientToImageCoords(evt.clientX, evt.clientY);
    if (this.props.imageMouseUp) {
      this.props.imageMouseUp(px, py);
    }
  }

  private canvasTouchStart(evt: TEvent<HTMLCanvasElement>) {
    if (evt.changedTouches.length !== 1) {
      return;
    }

    const trackedTouch = evt.changedTouches.item(0);
    this._trackedTouchId = trackedTouch.identifier;

    const [px, py] = this.clientToImageCoords(
      trackedTouch.clientX,
      trackedTouch.clientY
    );
    if (this.props.imageMouseDown) {
      this.props.imageMouseDown(px, py);
    }
  }

  private canvasTouchChange(evt: TEvent<HTMLCanvasElement>) {
    const trackedTouch = this.trackedTouch(evt);
    if (!trackedTouch) {
      return;
    }

    const [px, py] = this.clientToImageCoords(
      trackedTouch.clientX,
      trackedTouch.clientY
    );
    if (this.props.imageMouseMove) {
      this.props.imageMouseMove(px, py);
    }
  }

  private canvasTouchEnd(evt: TEvent<HTMLCanvasElement>) {
    const trackedTouch = this.trackedTouch(evt);
    if (!trackedTouch) {
      return;
    }

    const [px, py] = this.clientToImageCoords(
      trackedTouch.clientX,
      trackedTouch.clientY
    );
    if (this.props.imageMouseUp) {
      this.props.imageMouseUp(px, py);
    }

    this._trackedTouchId = null;
  }
}

export default ScreenDisplay;
