import { Button } from "@mui/material";
import { ReactNode } from "react";
import styled from "styled-components";
import { FormPageBody } from "./FormPageBody";

export type EmptyStateContentProps = {
  title: string;
  buttonTitle: string;
  children: ReactNode;
  onClick: () => void;
};

export function EmptyStateContent(props: EmptyStateContentProps) {
  return (
    <FormPageBody>
      <h2>{props.title}</h2>

      {props.children}

      <ButtonContainer>
        <Button variant="contained" onClick={() => props.onClick()}>
          {props.buttonTitle}
        </Button>
      </ButtonContainer>
    </FormPageBody>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 64px;
  width: 100%;
`;
