import SaveIcon from "@mui/icons-material/Save"
import { Alert, AlertColor, IconButton, Snackbar, Tab, Tabs } from "@mui/material"
import { HatDeviceConfiguration, HatMenuDeviceConfiguration, HatRetroControllerConfiguration, JsonDeserializationContext, JsonSerializationContext } from "hat-common"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { apiClient } from "../../../utilities/api-client"
import { AppSystemContext } from "../../../utilities/app-system-context"
import { ScreenTitleBar } from "../../common/ScreenTitleBar"
import { TabPanel } from "../../common/TabPanel"
import { ControlCategories, ControlCategory } from "./control-editing/ControlEditingSupport"
import { DeviceControlListEditor } from "./DeviceControlListEditor"
import "./HatDeviceEditor.css"
import { MenuDeviceSettingsEditor } from "./MenuDeviceSettingsEditor"
import { MenuEditor } from "./MenuEditor"
import { RetroControllerDeviceSettingsEditor } from "./RetroControllerDeviceSettingsEditor"

export interface HatDeviceEditorProps {
	editedDeviceId: string
}

const DeviceEditorTabs: {
	tabId: string
	deviceType: Function
	editorTitle: string
	createEditor: (device: HatDeviceConfiguration, updateDevice: (device: HatDeviceConfiguration) => void) => React.ReactNode
}[] = [
	{
		tabId: "menu-editor",
		deviceType: HatMenuDeviceConfiguration,
		editorTitle: "Menu Editor",
		createEditor: (device, updateDevice) => <DeviceMenuEditor device={device as HatMenuDeviceConfiguration} onDeviceUpdated={updateDevice} />,
	},

	{
		tabId: "menu-settings-editor",
		deviceType: HatMenuDeviceConfiguration,
		editorTitle: "Settings",
		createEditor: (device, updateDevice) => <MenuDeviceSettingsEditor device={device as HatMenuDeviceConfiguration} onDeviceUpdated={updateDevice} />,
	},

	...Object.entries(ControlCategories).map(([controlCategoryName, controlCategoryInfo]) => ({
		tabId: `controls-list-editor-${controlCategoryName}`,
		deviceType: HatRetroControllerConfiguration,
		editorTitle: controlCategoryInfo.displayName,
		createEditor: (device: HatDeviceConfiguration, updateDevice: (device: HatDeviceConfiguration) => void) => <DeviceControlListEditor device={device as HatRetroControllerConfiguration} onDeviceUpdated={updateDevice} controlCategory={controlCategoryName as ControlCategory} />,
	})),

	{
		tabId: "retro-settings-editor",
		deviceType: HatRetroControllerConfiguration,
		editorTitle: "Settings",
		createEditor: (device, updateDevice) => <RetroControllerDeviceSettingsEditor device={device as HatRetroControllerConfiguration} onDeviceUpdated={updateDevice} />,
	},
]

export function HatDeviceEditor(props: HatDeviceEditorProps) {
	const [currentTab, setCurrentTab] = useState<string>("")
	const [dirty, setDirty] = useState(false)
	const [deviceDraft, setDeviceDraft] = useState<HatDeviceConfiguration>()
	const [alertMessage, setAlertMessage] = useState<string>()
	const [alertSeverity, setAlertSeverity] = useState<AlertColor>()

	const systemContext = useContext(AppSystemContext)

	const showAlert = useCallback(
		(severity: AlertColor, message: string) => {
			setAlertSeverity(severity)
			setAlertMessage(message)
		},
		[setAlertMessage, setAlertSeverity]
	)

	const saveDeviceConfig = async () => {
		const saveRequest = {
			systemId: systemContext.systemId,
			deviceId: deviceDraft!.deviceId,
			deviceConfig: new JsonSerializationContext().writeObject(deviceDraft!),
		}

		try {
			await apiClient.invokePostApi("system/hat-device-config", saveRequest)
			setDirty(false)
			showAlert("success", "Configuration saved sucessfuly.")
		} catch (e: any) {
			showAlert("error", e.message)
		}
	}

	const updateDevice = (device: HatDeviceConfiguration) => {
		setDeviceDraft(device)
		setDirty(true)
	}

	useEffect(() => {
		const loadDeviceConfig = async () => {
			const jsonResponse = await apiClient.invokeGetApi("system/hat-device-config", {
				s: systemContext.systemId,
				d: props.editedDeviceId,
			})

			const devConfig = new JsonDeserializationContext(jsonResponse.data).readObject(HatDeviceConfiguration)

			setDeviceDraft(devConfig)
		}

		loadDeviceConfig()
	}, [props.editedDeviceId, systemContext.systemId])

	const deviceEditorTabs = useMemo(() => DeviceEditorTabs.filter((tabDesc) => deviceDraft instanceof tabDesc.deviceType), [deviceDraft])

	useEffect(() => {
		if (!deviceEditorTabs.find((tabDesc) => tabDesc.tabId === currentTab) && deviceEditorTabs.length) {
			setCurrentTab(deviceEditorTabs[0].tabId)
		}
	}, [currentTab, deviceEditorTabs])

	return (
		<div className="deviceEditor">
			<ScreenTitleBar>
				<ScreenTitleBar.BreadCrumbs>
					<Link to="../devices">Device List</Link>
				</ScreenTitleBar.BreadCrumbs>

				{deviceDraft && (
					<>
						<ScreenTitleBar.TitleText>{deviceDraft.deviceName}</ScreenTitleBar.TitleText>

						<ScreenTitleBar.ActionBar>
							<IconButton color="primary" disabled={!dirty} onClick={() => saveDeviceConfig()}>
								<SaveIcon />
							</IconButton>

							<Tabs value={currentTab} onChange={(event, tab) => setCurrentTab(tab)}>
								{deviceEditorTabs.map((tabDescriptor) => (
									<Tab label={tabDescriptor.editorTitle} key={tabDescriptor.tabId} value={tabDescriptor.tabId} />
								))}
							</Tabs>
						</ScreenTitleBar.ActionBar>
					</>
				)}
			</ScreenTitleBar>

			{deviceDraft &&
				deviceEditorTabs.map((tabDescriptor) => (
					<TabPanel value={currentTab} index={tabDescriptor.tabId}>
						{tabDescriptor.createEditor(deviceDraft, updateDevice)}
					</TabPanel>
				))}

			<Snackbar open={Boolean(alertMessage)} autoHideDuration={5000} onClose={() => setAlertMessage(undefined)}>
				<Alert onClose={() => setAlertMessage(undefined)} severity={alertSeverity}>
					{alertMessage}
				</Alert>
			</Snackbar>
		</div>
	)
}

function DeviceMenuEditor(props: { device: HatMenuDeviceConfiguration; onDeviceUpdated: (device: HatDeviceConfiguration) => void }) {
	return <MenuEditor menu={props.device.rootMenu} onMenuUpdated={(menu) => props.onDeviceUpdated(props.device.withUpdatedMenu(menu))}></MenuEditor>
}
