import Blockly, {
  FieldDropdown,
  FieldDropdownConfig,
  MenuOption,
} from "blockly";
import {
  ControlBindingType,
  DeviceDescriptor,
  HatRetroConsoleVariableDefinition,
  HatRetroControlConfigurationSchemaInformation,
  HatRetroControlConfigurationSchemaInformationType,
  HatRetroControlTypeTag,
} from "hat-common";
import { useCallback, useContext, useEffect } from "react";
import { BlocklyWorkspace } from "react-blockly";
import { AppSystemContext } from "../../../../utilities/app-system-context";
import {
  getAvailableDeviceDescriptors,
  humanizeDeviceName,
} from "../../../../utilities/device-descriptors-cache";
import { SuspenseView, useSuspense } from "../../../common/Suspense";
import { default as blocklyDefinitions } from "./ControlBindingsEditor.blocklyBlockDefinitions.json";
import "./ControlBindingsEditor.css";
import { ControlEditingSupportList } from "./ControlEditingSupport";

const BLOCK_DEFAULT_VERTICAL_SPACING = 50;
const BLOCK_DEFAULT_HORIZONTAL_SPACING = 20;
const BLOCK_DEFAULT_VERTICAL_TOP = 20;

const ENABLE_DEBUGGING_VIEW = false;

const AddVariableButtonCallbackKey = "addVariableButtonPressed";

const ToolboxCategories: {
  name: string;
  displayName: string;
}[] = [
  { name: "commands", displayName: "Commands" },
  { name: "control", displayName: "Control" },
  { name: "expr", displayName: "Variables and Math" },
  { name: "sensors", displayName: "Sensors" },
  { name: "animators", displayName: "Animators" },
];

const toolboxCategories = {
  kind: "categoryToolbox",
  contents: ToolboxCategories.map((category) => ({
    kind: "category",
    cid: category.name,
    name: category.displayName,
    colour: blocklyDefinitions.find((def) => def.category === category.name)
      ?.colour,
    contents: blocklyDefinitions
      .filter((def) => def.category === category.name)
      .map((def) => ({ kind: "block", type: def.type })),
  })),
};

toolboxCategories.contents
  .find((category) => category.cid === "expr")
  ?.contents.unshift({
    kind: "button",
    text: "Add Variable...",
    callbackKey: AddVariableButtonCallbackKey,
  } as any);

Blockly.Extensions.registerMutator("bindingPropertyNameSupport", {
  saveExtraState: function () {
    return {
      propertyName: this._boundPropertyName,
    };
  },

  loadExtraState: function (state: any) {
    this._boundPropertyName = state.propertyName;
  },
});

type WorkspaceExtensions = {
  systemId: string;
  availableDevices: DeviceDescriptor[];
};

const workspaceExtensions: WeakMap<Blockly.Workspace, WorkspaceExtensions> =
  new WeakMap();

function getWorkspaceExtensions(
  workspace: Blockly.Workspace
): WorkspaceExtensions {
  if (!workspaceExtensions.has(workspace)) {
    workspaceExtensions.set(workspace, { systemId: "", availableDevices: [] });
  }

  return workspaceExtensions.get(workspace)!;
}

class BlocklyCommandEditor extends Blockly.FieldDropdown {
  constructor(config: FieldDropdownConfig) {
    super(BlocklyCommandEditor.generateMenu, undefined, config);
  }

  private static generateMenu(this: FieldDropdown): MenuOption[] {
    const workspace = this.getSourceBlock()?.workspace;
    if (!workspace) {
      return [["Select command", "loading"]];
    }

    const wsExtensions = getWorkspaceExtensions(workspace);

    const fullMenu = wsExtensions.availableDevices.flatMap((device) =>
      device.deviceType === "pushButton"
        ? device.pushCommands.map(
            (command) =>
              [
                `${humanizeDeviceName(
                  wsExtensions.systemId,
                  device.fullName
                )} -- ${command}`,
                `${command}|${device.fullName}`,
              ] as MenuOption
          )
        : []
    );

    fullMenu.sort((a, b) => (a[0] as string).localeCompare(b[0] as string));

    return fullMenu;
  }

  public static fromJson(json: any) {
    return new BlocklyCommandEditor(json);
  }
}

Blockly.fieldRegistry.register("field_device_command", BlocklyCommandEditor);

Blockly.defineBlocksWithJsonArray(blocklyDefinitions);

const controlBindingEditorTheme = Blockly.Theme.defineTheme(
  "controlBindingEditorTheme",
  {
    name: "controlBindingEditorTheme",
    base: Blockly.Themes.Classic,
    fontStyle: {
      family: "Arial",
      weight: "normal",
      size: 10,
    },
  }
);

type ControlBindingsEditorProps = {
  bindingsBlocklyJson: any;
  variablesList: Record<string, HatRetroConsoleVariableDefinition>;
  onBindindingsBlocklyJsonChanged: (blocklyJson: any) => void;
  controlBindingTypeTag: HatRetroControlTypeTag;
  className?: string;
};

export function ControlBindingsEditor({
  bindingsBlocklyJson,
  variablesList,
  controlBindingTypeTag,
  onBindindingsBlocklyJsonChanged,
  className,
}: ControlBindingsEditorProps) {
  const systemContext = useContext(AppSystemContext);

  const [systemDeviceDescriptors, devicesSuspenseHandle] =
    useSuspense(async () => {
      return await getAvailableDeviceDescriptors(systemContext.systemId);
    }, [systemContext.systemId]);

  useEffect(() => {
    const oldJson = JSON.stringify(bindingsBlocklyJson);
    ensureBlocklyDocumentFitsEditingSupport(
      bindingsBlocklyJson,
      controlBindingTypeTag,
      variablesList
    );
    const updatedJson = JSON.stringify(bindingsBlocklyJson);
    if (oldJson !== updatedJson) {
      onBindindingsBlocklyJsonChanged(bindingsBlocklyJson);
    }
  }, [
    bindingsBlocklyJson,
    controlBindingTypeTag,
    onBindindingsBlocklyJsonChanged,
    variablesList,
  ]);

  const autoLayoutWorkspace = useCallback((workspace: Blockly.WorkspaceSvg) => {
    const topBlocks = workspace.getTopBlocks(false);
    const blocksToLayout: Blockly.BlockSvg[] = [];
    let bottomY = 0;

    topBlocks.forEach((block) => {
      const position = block.getRelativeToSurfaceXY();

      if (position.y === 0) {
        blocksToLayout.push(block);
      } else {
        bottomY = Math.max(bottomY, block.height + position.y);
      }
    });

    if (bottomY) {
      bottomY += BLOCK_DEFAULT_VERTICAL_SPACING;
    } else {
      bottomY = BLOCK_DEFAULT_VERTICAL_TOP;
    }

    blocksToLayout.forEach((blockToLayout) => {
      const position = blockToLayout.getRelativeToSurfaceXY();
      blockToLayout.moveTo(
        Object.assign(position, {
          x: BLOCK_DEFAULT_HORIZONTAL_SPACING,
          y: bottomY,
        })
      );
      bottomY += blockToLayout.height + BLOCK_DEFAULT_VERTICAL_SPACING;
    });
  }, []);

  const handleWorkspaceChanged = useCallback(
    (workspace: Blockly.WorkspaceSvg) => {
      autoLayoutWorkspace(workspace);
    },
    [autoLayoutWorkspace]
  );

  const handleOnInject = useCallback(
    (workspace: Blockly.WorkspaceSvg) => {
      workspace.registerButtonCallback(
        AddVariableButtonCallbackKey,
        (button) => {
          Blockly.Variables.createVariableButtonHandler(
            button.getTargetWorkspace()
          );
        }
      );

      const workspaceExtensions = getWorkspaceExtensions(workspace);
      workspaceExtensions.systemId = systemContext.systemId;
      workspaceExtensions.availableDevices = systemDeviceDescriptors!;
    },
    [systemContext.systemId, systemDeviceDescriptors]
  );

  return (
    <SuspenseView suspenseHandle={devicesSuspenseHandle}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className={className}
      >
        <BlocklyWorkspace
          initialJson={bindingsBlocklyJson}
          className="blockly-workspace"
          toolboxConfiguration={toolboxCategories}
          workspaceConfiguration={{
            collapse: false,
            trashcan: false,
            disable: false,
            renderer: "zelos",
            sounds: false,
            theme: controlBindingEditorTheme,
          }}
          onInject={handleOnInject}
          onJsonChange={onBindindingsBlocklyJsonChanged}
          onWorkspaceChange={handleWorkspaceChanged}
        />

        {ENABLE_DEBUGGING_VIEW && (
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: "1" }}>
              {JSON.stringify(bindingsBlocklyJson)}
            </div>
          </div>
        )}
      </div>
    </SuspenseView>
  );
}

function ensurePropertyBindingBlocks(
  blocklyDocument: any,
  controlTypeTag: HatRetroControlTypeTag
) {
  const controlTagInformation = HatRetroControlConfigurationSchemaInformation[
    controlTypeTag
  ] as HatRetroControlConfigurationSchemaInformationType;

  Object.keys(controlTagInformation.bindings).forEach((bindingName) => {
    const bindingInfo = controlTagInformation.bindings[bindingName];

    let existingBlock = blocklyDocument.blocks.blocks.find(
      (block: any) => block.extraState.propertyName === bindingName
    );
    if (!existingBlock) {
      existingBlock = {
        inputs: {},
      };
      blocklyDocument.blocks.blocks.push(existingBlock);
    }

    Object.assign(existingBlock, {
      type:
        bindingInfo.bindingType === ControlBindingType.Command
          ? "command_binding"
          : bindingInfo.bindingType === ControlBindingType.Datum
          ? "property_binding"
          : null,
      deletable: false,
      fields: {
        DISPLAY_NAME:
          ControlEditingSupportList[controlTypeTag].bindings[bindingName]
            .displayName,
      },
      extraState: {
        propertyName: bindingName,
      },
    });
  });
}

function ensureVariables(
  blocklyDocument: any,
  variables: Record<string, HatRetroConsoleVariableDefinition>
) {
  const varById: Record<string, HatRetroConsoleVariableDefinition> = {};
  (blocklyDocument.variables || []).forEach((docVar: any) => {
    varById[docVar.id] = { name: docVar.name };
  });
  Object.assign(varById, variables);

  blocklyDocument.variables = Object.entries(varById).map(
    ([varId, varDefinition]) => ({ name: varDefinition.name, id: varId })
  );
}

function ensureBlocklyDocumentFitsEditingSupport(
  blocklyDocument: any,
  controlTypeTag: HatRetroControlTypeTag,
  variables: Record<string, HatRetroConsoleVariableDefinition>
) {
  ensurePropertyBindingBlocks(blocklyDocument, controlTypeTag);
  ensureVariables(blocklyDocument, variables);
}
