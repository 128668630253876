import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Switch, TextField } from "@mui/material";
import { useCallback } from "react";
import styled from "styled-components";
import { usePropertiesUpdater } from "../../../../utilities/hooks";
import { HorizontalStack, VerticalStack } from "../../../common/CommonStyled";
import "../../../common/EditButton.css";
import { ControlPropertiesEditorProps } from "./ControlEditingSupport";

export function ControlSettingsEditorMultiLed({
  controlConfiguration,
  onControlConfigurationChanged,
}: ControlPropertiesEditorProps<"multi_led">) {
  const controlUpdater = usePropertiesUpdater(
    controlConfiguration,
    onControlConfigurationChanged
  );

  const setThresholdsArray = useCallback(
    (thresholds: number[]) => {
      const newControl = {
        ...controlConfiguration,
        otherSettings: {
          ...controlConfiguration.otherSettings,
          thresholds,
        },
      };

      const pinsConnectionVector = (newControl.connections as any).pins || [];
      if (pinsConnectionVector.length > thresholds.length) {
        pinsConnectionVector.splice(
          thresholds.length,
          pinsConnectionVector.length
        );
      }

      const invertVector = controlConfiguration.otherSettings.inv || [];
      if (invertVector.length > thresholds.length) {
        invertVector.splice(thresholds.length, invertVector.length);
      }
      while (invertVector.length < thresholds.length) {
        invertVector.push(false);
      }
      controlConfiguration.otherSettings.inv = invertVector;

      onControlConfigurationChanged(newControl);
    },
    [controlConfiguration, onControlConfigurationChanged]
  );

  const currentThresholds = controlConfiguration.otherSettings.thresholds;

  const handleAddThresholdClick = useCallback(() => {
    setThresholdsArray([
      ...currentThresholds,
      currentThresholds.length
        ? currentThresholds[currentThresholds.length - 1]
        : 0,
    ]);
  }, [currentThresholds, setThresholdsArray]);

  const handleUpdateThreshold = useCallback(
    (index: number, value: number) => {
      const newThresholds = [...currentThresholds];
      newThresholds[index] = value;
      setThresholdsArray(newThresholds);
    },
    [currentThresholds, setThresholdsArray]
  );

  const handleUpdateInvert = useCallback(
    (index: number, value: boolean) => {
      const newInvert = (controlConfiguration.otherSettings.inv || []).concat();
      newInvert[index] = value;
      controlUpdater({
        otherSettings: {
          ...controlConfiguration.otherSettings,
          inv: newInvert,
        },
      });
    },
    [controlConfiguration.otherSettings, controlUpdater]
  );

  const handleDeleteThreshold = useCallback(
    (index: number) => {
      const newThresholds = [...currentThresholds];
      newThresholds.splice(index, 1);
      setThresholdsArray(newThresholds);
    },
    [currentThresholds, setThresholdsArray]
  );

  return (
    <ThresholdsLabelAndListContainer>
      <div>Thresholds:&nbsp;</div>

      <ThresholdsListContainer>
        <ThresholdRow>
          <ThresholdsEditAndDeleteContainer>
            <TextField
              type="number"
              size="small"
              inputProps={{
                max: currentThresholds[0],
              }}
              value={controlConfiguration.otherSettings.lbound}
              onChange={(event) =>
                controlUpdater({
                  otherSettings: {
                    ...controlConfiguration.otherSettings,
                    lbound: parseInt(event.target.value),
                  },
                })
              }
            ></TextField>
          </ThresholdsEditAndDeleteContainer>
          <ThresholdBracket last={false} first={true} />
        </ThresholdRow>
        {currentThresholds.map((threshold, thresholdIndex, thresholds) => (
          <ThresholdRow>
            <ThresholdsEditAndDeleteContainer>
              <TextField
                type="number"
                size="small"
                inputProps={{
                  min:
                    thresholdIndex > 0
                      ? thresholds[thresholdIndex - 1]
                      : controlConfiguration.otherSettings.lbound,
                  max:
                    thresholdIndex < thresholds.length - 1
                      ? thresholds[thresholdIndex + 1]
                      : undefined,
                }}
                value={threshold}
                onChange={(event) =>
                  handleUpdateThreshold(
                    thresholdIndex,
                    parseInt(event.target.value) ?? 0
                  )
                }
              ></TextField>
              {thresholds.length > 1 && (
                <DeleteIcon
                  className="editButton"
                  onClick={() => handleDeleteThreshold(thresholdIndex)}
                />
              )}
            </ThresholdsEditAndDeleteContainer>
            <ThresholdBracket
              last={thresholdIndex === thresholds.length - 1}
              first={false}
              caption={`LED ${thresholdIndex}`}
              inverted={
                controlConfiguration.otherSettings.inv?.[thresholdIndex]
              }
              onInvertChange={(invertValue) =>
                handleUpdateInvert(thresholdIndex, invertValue)
              }
            />
          </ThresholdRow>
        ))}

        <ThresholdAddButton
          onClick={handleAddThresholdClick}
          variant="outlined"
          size="small"
        >
          Add
        </ThresholdAddButton>
      </ThresholdsListContainer>
    </ThresholdsLabelAndListContainer>
  );
}

function ThresholdBracket({
  last,
  first,
  caption,
  inverted,
  onInvertChange,
}: {
  last: boolean;
  first: boolean;
  caption?: string;
  inverted?: boolean;
  onInvertChange?: (invertValue: boolean) => void;
}) {
  return (
    <VerticalStack>
      <HorizontalStack>
        <ThresholdBracketLeftPartTop data-first={first} />
        {caption && (
          <>
            <ThresholdBracketLabel>
              {caption}
              <br />
              <Switch
                checked={inverted}
                size="small"
                onChange={(event, checked) =>
                  onInvertChange && onInvertChange(checked)
                }
              />
              &nbsp;
              <InvertLabel>Invert</InvertLabel>
            </ThresholdBracketLabel>
          </>
        )}
      </HorizontalStack>
      <ThresholdBracketLeftPartBottom data-last={last} />
    </VerticalStack>
  );
}

const ThresholdsLabelAndListContainer = styled(HorizontalStack)`
  align-items: baseline;
`;

const ThresholdsEditAndDeleteContainer = styled(HorizontalStack)`
  align-items: center;
  width: 11rem;
  padding-top: 1.5rem;
`;

const ThresholdsListContainer = styled(VerticalStack)`
  align-items: flex-start;
  max-height: 50vh;
  overflow-y: auto;

  input {
    width: 7rem;
  }
`;

const ThresholdRow = styled(HorizontalStack)``;

const ThresholdBracketLeftPartTop = styled.div`
  border-right: solid 1px silver;
  border-bottom: solid 1px silver;

  width: 1rem;
  height: 3.5rem;

  margin-bottom: -1px;

  &[data-first="true"] {
    border: 0;
  }
`;

const ThresholdBracketLeftPartBottom = styled.div`
  border-top: solid 1px silver;
  border-right: solid 1px silver;
  width: 1rem;
  height: 1.5rem;

  &[data-last="true"] {
    border: 0;
  }
`;

const ThresholdBracketLabel = styled.span`
  margin-left: 1rem;
`;

const ThresholdAddButton = styled(Button)`
  margin-top: 1rem !important;
`;

const InvertLabel = styled.span`
  font-size: 12px;
`;
