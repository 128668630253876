import { Button, CardContent, Dialog, Switch } from "@mui/material";
import { ClassTagForClass, DeviceConnectionSummary } from "hat-common";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../utilities/api-client";
import { AppSystemContext } from "../../../utilities/app-system-context";
import { ScreenTitleBar } from "../../common/ScreenTitleBar";
import { AssociatedConnectedDeviceEditingSupport } from './integration-editors/ConnectedDeviceEditingSupport';
import './IntegrationListEditor.css';

import { CardGrid, CardListAddCard, CardListCard, FlexCardActions, FlexCardActionsSpacer } from '../../common/CommonStyled';
import { EmptyStateContent } from '../../common/EmptyStateContent';
import { SuspenseView, useSuspense } from '../../common/Suspense';
import { AddConnectionDialog } from './AddConnectionDialog';
import "./integration-editors/index";

export interface IntegrationListEditorProps {    
}

export function IntegrationListEditor(props: IntegrationListEditorProps) {
    
    
    const [deviceConnectionList, deviceConnectionListSuspenseHandle, reloadDeviceConnectionList] = 
        useSuspense<DeviceConnectionSummary[]>(async () => {
            const jsonResponse = await apiClient.invokeGetApi("system/device-connections-info", {
                "s": systemContext.systemId
            });
          
            return jsonResponse.data.connectionsInfo;    
        }, []);

    //const [deviceConnectionList, setDeviceConnectionList] = useState<DeviceConnectionSummary[]>([]);
    const [connectionAddActive, setConnectionAddActive] = useState<boolean>(false);
    
    const systemContext = useContext(AppSystemContext);

    const navigate = useNavigate();


    const deleteDeviceConnection = async (dc: DeviceConnectionSummary) => {
        const deleteRequest = {
            systemId: systemContext.systemId,
            connectionId: dc.id            
        };

        try {
            await apiClient.invokePostApi('system/delete-device-connection', deleteRequest);
        } finally {
            await reloadDeviceConnectionList();
        }
    }

    const editDeviceConnection = (dc: DeviceConnectionSummary) => {
        navigate(`connected-device/${dc.id}`);
    };

    const setDeviceConnectionEnabled = async (dc: DeviceConnectionSummary, enabled: boolean) => {
        try {
            await apiClient.setConnectionEnabled(systemContext.systemId, dc.id, enabled);
        } finally {
            await reloadDeviceConnectionList();
        }
    }
    
    const connectionRow = (d: DeviceConnectionSummary) => {
        
        const editingSupportCtor = AssociatedConnectedDeviceEditingSupport.Find((k, v) => ClassTagForClass(k) === d.type);
        const editingSupport = new (editingSupportCtor)("");
        return (
            <CardListCard>
                <CardContent className="listRow">
                    <div className="titleAndDescContainer">
                        <div className="title">
                            {d.name}
                        </div>
                        
                        <div className="desc">                    
                            <div>{editingSupport.displayName}</div>
                        </div>                                  
                    </div>

                </CardContent>

                <FlexCardActions>
                    <Switch checked={d.enabled} onChange={(_, value) => setDeviceConnectionEnabled(d, value)}/>
                    <FlexCardActionsSpacer/>
                    <Button onClick={() => editDeviceConnection(d)}>Edit</Button>
                    <Button onClick={() => deleteDeviceConnection(d)}>Delete</Button>
                </FlexCardActions>
            </CardListCard>
        );
    }
    
    const handleAddDialogClose = useCallback((idOrNull) => {
        setConnectionAddActive(false);
        if(idOrNull) {
            navigate(`connected-device/${idOrNull}`);
        }
    }, [navigate]);

    return (
        <div>
            <div className="integrationsList">
                <ScreenTitleBar>
                    <ScreenTitleBar.TitleText>System Integrations</ScreenTitleBar.TitleText>                    
                </ScreenTitleBar>
                <SuspenseView suspenseHandle={deviceConnectionListSuspenseHandle}>
                    {deviceConnectionList?.length ? 

                        <CardGrid>
                            {deviceConnectionList?.map((c) => connectionRow(c))}

                            <CardListAddCard style={{height: '140px'}}>
                                <Button 
                                    onClick={ () => setConnectionAddActive(true) } >

                                    Add Connection
                                </Button>
                            </CardListAddCard>
                        </CardGrid> :

                        <EmptyStateContent 
                            title="This system has no connections set up." 
                            buttonTitle="Add Connection"
                            onClick={() => setConnectionAddActive(true)}
                            >
                                <p>To allow your HAT devices to control smart home devices, you need to setup 
                                    connections between this system and the device you want to control. Once you setup
                                    this connection, you'll be able to create HAT device menu items that operate 
                                    connected devices.
                                </p>
                                <p>
                                    You can connect controlled devices to this system by clicking the button below.
                                </p>
                        </EmptyStateContent>
                    }
                </SuspenseView>
            </div>

            <Dialog                    
                    maxWidth="md"
                    fullWidth={true}
                    open={connectionAddActive}>
                        <AddConnectionDialog onClose={handleAddDialogClose}/>
            </Dialog>
        </div>);
}