import React from "react"
import { Button } from "@mui/material"
import { useRef } from "react"

export interface UploadButtonProps {
	label: string
	onFileUpload: (file: File) => void
}

export function UploadButton(props: UploadButtonProps) {
	const fileInputRef = useRef<HTMLInputElement>(null)

	const handleFileUpload = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]
		if (file) {
			props.onFileUpload(file)
		}
	}

	return (
		<>
			<Button onClick={handleFileUpload}>{props.label}</Button>
			<input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
		</>
	)
}
