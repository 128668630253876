import './ScreenTitleBar.css';

type ScreenTitleBarProps = {
  children?: React.ReactNode;
}

export function ScreenTitleBar(props: ScreenTitleBarProps) {
  return <div className="screenTitleBar">{props.children}</div>;
}

ScreenTitleBar.TitleText = function(props: ScreenTitleBarProps) {
  return <div className="titleText">{props.children}</div>;
}


ScreenTitleBar.BreadCrumbs = function(props: ScreenTitleBarProps) {
  const children: any[] = 
  props.children instanceof Array ? props.children :
  props.children ? [props.children] :
  [];

  return <div className="breadCrumbs">{(children).map((child) => <>{child} &raquo; </>)}</div>;
}

ScreenTitleBar.ActionBar = function(props: ScreenTitleBarProps) {
  return <div className="actionBar">{props.children}</div>;
}
