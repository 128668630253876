import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { AppSystemContext } from "./utilities/app-system-context";
import ReactCodeInput from 'react-verification-code-input';

import './DeviceAddDialog.css';
import { apiClient } from "./utilities/api-client";
import { HatDeviceType } from "hat-common";
import { HatDeviceTypeInfos } from "./utilities/device-type-info";

export interface DeviceAddDialogProps {
    onComplete?: (result: boolean) => void;
}

enum DeviceAddPhase {
    GenerateDeviceToken,
    WaitForDeviceConsumption,
    WaitForConsoleConfirmation,
    Complete
}

export interface DeviceAddDialogState {
    phase: DeviceAddPhase;

    ticketId?: string;
    deviceToken?: string;
    deviceType?: string;
    deviceSerialNumber?: string;
    consoleTokenNeeded?: boolean;

    error?: string | null;    
}

export class DeviceAddDialog extends React.Component<DeviceAddDialogProps, DeviceAddDialogState> {
    constructor(props: DeviceAddDialogProps) {
        super(props);
        this.state = {
            phase: DeviceAddPhase.GenerateDeviceToken
        }
    }    

    static contextType = AppSystemContext;
    context!: React.ContextType<typeof AppSystemContext>;

    private pollingTimer: any;
    private pollingInProgress: boolean = false;
    private codeSegments: string[] = ["", "", "", ""];

    componentWillUnmount() {
        if(this.pollingTimer !== null) {
            clearInterval(this.pollingTimer);
            this.pollingTimer = null;
        }
    }

    componentDidMount() {
        this.pollingTimer = setInterval(() => this.onPollingTimerFired(), 1000);
        this.generateDeviceToken();
    }

    render(): React.ReactNode {   
        switch(this.state.phase) {
            case DeviceAddPhase.GenerateDeviceToken:
                return this.renderDeviceTokenGenerationUi();                
            case DeviceAddPhase.WaitForDeviceConsumption:
                return this.renderWaitForDeviceUi();                
            case DeviceAddPhase.WaitForConsoleConfirmation:
                return this.renderWaitForConsoleConfirmation();                
            case DeviceAddPhase.Complete:
                return this.renderProcessCompletion();                
        }
    }
    
    private renderDeviceTokenGenerationUi() {
        return <div className="deviceAddDialog">
            <div>Please Wait</div>
            <CircularProgress />
        </div>;
    }

    private renderWaitForDeviceUi() {
        const deviceTokenPresentation = this.state.deviceToken?.split('').map((c, i) => {
            let s = "";
            
            switch(c) {
                case "l":
                    s = "\u25c0\u205f";
                    break;
                case "r":
                    s = "\u25b6\u205f";
                    break;
                case "d":
                    s = "\u25bc\u205f";
                    break;
                case "u":
                    s = "\u25b2\u205f";
                    break;
            }

            if(i % 3 === 2) {
                s += "\u3000";
            }
            
            return s;
        }).join('');

        return <div className="deviceAddDialog">
            <div>Please enter the following sequence on your device by tilting the navigation button in the designated directions:</div>
            <div className="deviceToken centerAlignedContent">{deviceTokenPresentation}</div>
            <Button onClick={() => this.close(false)}>Cancel</Button>
        </div>;
    }
    
    private nextCodeSegment(where: number) {
        const inputContainer = document.activeElement?.parentElement?.parentElement;
        const nextContainer = inputContainer?.nextSibling?.nextSibling;
        const nextContainerFirstInput = nextContainer?.firstChild?.firstChild;
        
        if(nextContainerFirstInput && nextContainerFirstInput instanceof HTMLInputElement) {
            nextContainerFirstInput.focus();
        }
    }

    private updateSegment(seg: number, value: string) {
        this.codeSegments[seg] = value;
        console.debug("Current code: " + this.codeSegments.join("-"));
    }

    get readableDeviceType(): string {
        const dt = HatDeviceType[this.state.deviceType as HatDeviceType];
        return HatDeviceTypeInfos[dt]?.displayName || "device";
    }
    
    private renderWaitForConsoleConfirmation() {
        return <div className="deviceAddDialog">
            {this.state.consoleTokenNeeded && <>
                <div>Please type-in below the code displayed on your {this.readableDeviceType}.</div>
                <div className="centerAlignedContent">
                    <div>Device Code</div>
                    <div className="multiField">
                        <ReactCodeInput 
                            fields={3} 
                            type="text" 
                            autoFocus={true}
                            onComplete={(e) => this.nextCodeSegment(0)}
                            onChange={(c) => this.updateSegment(0, c)}
                            fieldWidth={24}
                            fieldHeight={24}
                            />
                        <div>
                            -
                        </div>
                        <ReactCodeInput 
                            fields={3} 
                            type="text" 
                            autoFocus={false}
                            onComplete={(e) => this.nextCodeSegment(1)}
                            onChange={(c) => this.updateSegment(1, c)}
                            fieldWidth={24}
                            fieldHeight={24}
                            />
                        <div>
                            -
                        </div>
                        <ReactCodeInput 
                            fields={3} 
                            type="text" 
                            autoFocus={false}
                            onComplete={(e) => this.nextCodeSegment(2)}
                            onChange={(c) => this.updateSegment(2, c)}
                            fieldWidth={24}
                            fieldHeight={24}
                            />
                        <div>
                            -
                        </div>
                        <ReactCodeInput 
                            fields={3} 
                            type="text" 
                            autoFocus={false}
                            onComplete={(e) => this.nextCodeSegment(3)}
                            onChange={(c) => this.updateSegment(3, c)}
                            fieldWidth={24}
                            fieldHeight={24}
                            />
                    </div>
                    <div className="inlineError">{this.state.error || ''}</div>
                </div>
            </>}

            {!this.state.consoleTokenNeeded && <>
                <div>Please confirm registering your {this.readableDeviceType}, S/N {this.state.deviceSerialNumber}.</div>
            </>}

            <div>
                <Button onClick={() => this.close(false)}>Cancel</Button>
                <Button onClick={() => this.submitConsoleToken()}>Register</Button>
            </div>
        </div>;
    }

    private renderProcessCompletion() {
        if(this.state.error) {
            return <div className="deviceAddDialog">
                <div className="centerAlignedContent">Error. {this.state.error}</div>
                <Button onClick={() => this.close(false)}>Close</Button>
            </div>;
        } else {
            return <div className="deviceAddDialog">
                <div className="centerAlignedContent">Device was registered successfuly.</div>
                <Button onClick={() => this.close(true)}>Close</Button>
            </div>;
        }
    }

    private close(res: boolean) {
        if(this.props.onComplete) {
            this.props.onComplete(res);
        }
    }

    private async submitConsoleToken() {
        try {
            const codeInput = this.codeSegments.join('-');
            this.setState({ error: null });

            if(this.state.consoleTokenNeeded &&
                !/[A-HJ-NP-Z2-9]{3}-[A-HJ-NP-Z2-9]{3}-[A-HJ-NP-Z2-9]{3}-[A-HJ-NP-Z2-9]{3}/.test(codeInput)) {
                this.setState({ error: "Invalid code input." });
                return;
            } 
            
            const submitTokenRequest = {
                systemId: this.context.systemId,
                tokenId: this.state.ticketId,
                consoleToken: this.state.consoleTokenNeeded ? codeInput : null
            };
            
            try {
                await apiClient.invokePostApi('system/submit-device-reg-ticket-console-token', submitTokenRequest);
                this.setState({
                    phase: DeviceAddPhase.Complete,
                    error: null
                });
            } catch(e) {
                // Detect noncritical errors
                throw e;
            }
        } catch(e) {
            this.setError((e as Error).toString());
        }
    }

    private async generateDeviceToken() {
        const createTokenRequest = {
            systemId: this.context.systemId
        };
      
        try {
            const createTokResult = 
                await apiClient.invokePostApi('system/create-new-hat-device-ticket', createTokenRequest);

            this.setState({
                phase: DeviceAddPhase.WaitForDeviceConsumption,
                ticketId: createTokResult.data.ticketId,
                deviceToken: createTokResult.data.deviceToken,
            });
        } catch(e) {
            this.setError((e as Error).toString());
        } 
    }

    private async onPollingTimerFired() {
        if(this.state.phase === DeviceAddPhase.WaitForDeviceConsumption &&
            !this.pollingInProgress) {
            this.pollingInProgress = true;
            try {
                const pollResult = await apiClient.invokeGetApi("system/poll-device-reg-ticket", {
                    "s": this.context.systemId,
                    "t": this.state.ticketId
                });

                switch(pollResult.data.status) {
                    case "created":
                        // Nothing left to do
                        break;

                    case "device_consumed":
                        this.setState({
                            phase: DeviceAddPhase.WaitForConsoleConfirmation,
                            deviceType: pollResult.data.deviceType,
                            consoleTokenNeeded: pollResult.data.consoleTokenNeeded,
                            deviceSerialNumber: pollResult.data.deviceSerialNumber,
                        });
                        break;

                    case "expired":
                        this.setError("Timeout registering device.");
                        break;

                    case "consumed":
                        this.setError("Device registration process error.");
                        break;                                
                }
            } finally {
                this.pollingInProgress = false;
            }
        }
    }

    private setError(errorMessage: string) {
        this.setState({
            phase: DeviceAddPhase.Complete,
            error: errorMessage
        });
    }
}