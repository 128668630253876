import { Switch } from "@mui/material";
import { HatRetroControlConfigurationWithTypeTag } from "hat-common";
import { useCallback } from "react";
import {
  FieldAndLabelRow,
  SpacedVerticalStack,
} from "../../../common/CommonStyled";
import "../../../common/EditButton.css";
import { ControlPropertiesEditorProps } from "./ControlEditingSupport";

export function ControlSettingsEditorPwmEditor({
  controlConfiguration,
  onControlConfigurationChanged,
}: ControlPropertiesEditorProps<"pwmLed">) {
  const updateDeviceSettings = useCallback(
    (
      settings: Partial<
        HatRetroControlConfigurationWithTypeTag<"pwmLed">["otherSettings"]
      >
    ) => {
      const newControl: HatRetroControlConfigurationWithTypeTag<"pwmLed"> = {
        ...controlConfiguration,
        otherSettings: {
          ...controlConfiguration.otherSettings,
          ...settings,
        },
      };

      onControlConfigurationChanged(newControl);
    },
    [controlConfiguration, onControlConfigurationChanged]
  );

  const currentSettings = controlConfiguration.otherSettings;

  return (
    <SpacedVerticalStack>
      <FieldAndLabelRow>
        <Switch
          checked={currentSettings.inv}
          size="small"
          onChange={(event, checked) => updateDeviceSettings({ inv: checked })}
        />{" "}
        Inverted
      </FieldAndLabelRow>
    </SpacedVerticalStack>
  );
}
