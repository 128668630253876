import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  HatScreenImage,
  JsonDeserializationContext,
  JsonSerializationContext,
} from "hat-common";
import React, { ReactNode } from "react";
import ScreenDisplay from "../ScreenDisplay";
import "./ScreenEditor.css";

import { ReactComponent as ToolIconEllipseF } from "./EllipseF.svg";
import { ReactComponent as ToolIconEllipseH } from "./EllipseH.svg";
import { ReactComponent as ToolIconBoxF } from "./BoxF.svg";
import { ReactComponent as ToolIconBoxH } from "./BoxH.svg";
import { ReactComponent as ToolIconFloodFill } from "./FloodFill.svg";
import { ReactComponent as ToolIconFreeform } from "./Freeform.svg";
import { ReactComponent as ToolIconSelect } from "./Select.svg";
import { ReactComponent as ToolIconLine } from "./Line.svg";

interface ScreenEditorProps {
  image: HatScreenImage;
  bkColor?: string;
  fgColor?: string;
}

interface ScreenEditorState {
  currentDrawingTool: DrawingTool;
  currentPen: boolean;
  editedImage: HatScreenImage;
  controlledImage: HatScreenImage;
}

const SCREEN_IMAGE_MIME_TYPE = "text/plain";

class ScreenEditor extends React.Component<
  ScreenEditorProps,
  ScreenEditorState
> {
  private _imageDisplay = React.createRef<ScreenDisplay>();

  private _selectTool = new SelectTool(this);

  private _tools: DrawingTool[] = [
    this._selectTool,
    new FloodFillDrawingTool(this),
    new FreeformDrawingTool(this),
    new LineDrawingTool(this),
    new BoxDrawingTool(this, true),
    new BoxDrawingTool(this, false),
    new EllipseDrawingTool(this, true),
    new EllipseDrawingTool(this, false),
  ];

  private _drawingPreviewImage: HatScreenImage | null = null;

  constructor(props: ScreenEditorProps) {
    super(props);
    this.state = {
      currentDrawingTool: this._tools[2],
      currentPen: true,
      editedImage: props.image.clone(),
      controlledImage: props.image,
    };
  }

  componentDidUpdate() {
    if (this.props.image !== this.state.controlledImage) {
      this.setState({
        editedImage: this.props.image.clone(),
        controlledImage: this.props.image,
      });
    }
  }

  render() {
    const toolSelectors = this._tools.map((drawingTool) => (
      <ToggleButton className="toolButton" value={drawingTool}>
        {drawingTool.buttonContent}
      </ToggleButton>
    ));

    return (
      <div className="screenEditor">
        <div className="toolbarsContainer">
          <ToggleButtonGroup
            className="toolSelectorsGroup"
            color="primary"
            orientation="vertical"
            value={this.state.currentDrawingTool}
            exclusive
            onChange={(event, tool) => this.setCurrentTool(tool)}
          >
            {toolSelectors}
          </ToggleButtonGroup>

          <div style={{ flexGrow: 1 }}>&nbsp;</div>

          <ToggleButtonGroup
            color="primary"
            className="drawingModeGroup"
            value={this.state.currentPen}
            exclusive
            onChange={(event, pen) =>
              pen != null && this.setState({ currentPen: pen })
            }
          >
            <ToggleButton value={true}>Draw</ToggleButton>
            <ToggleButton value={false}>Erase</ToggleButton>
          </ToggleButtonGroup>
        </div>

        <ScreenDisplay
          ref={this._imageDisplay}
          image={this.state.editedImage}
          imageMouseDown={this.imageMouseDown.bind(this)}
          imageMouseUp={this.imageMouseUp.bind(this)}
          imageMouseMove={this.imageMouseMove.bind(this)}
          grid={true}
          fgColor={this.props.fgColor}
          bkColor={this.props.bkColor}
        ></ScreenDisplay>

        <div className="speedDialDiv">
          <SpeedDial ariaLabel="More actions" icon={<SpeedDialIcon />}>
            <SpeedDialAction
              key="Cut"
              icon={<ContentCutIcon></ContentCutIcon>}
              tooltipTitle="Cut"
              onClick={() => this.cutRequested()}
            />
            <SpeedDialAction
              key="Copy"
              icon={<ContentCopyIcon></ContentCopyIcon>}
              tooltipTitle="Copy"
              onClick={() => this.copyRequested()}
            />
            <SpeedDialAction
              key="Paste"
              icon={<ContentPasteIcon></ContentPasteIcon>}
              tooltipTitle="Paste"
              onClick={() => this.pasteRequested()}
            />
          </SpeedDial>
        </div>
      </div>
    );
  }

  get currentSelection() {
    if (this.state.currentDrawingTool === this._selectTool) {
      return this._selectTool.selection;
    } else {
      return {
        startX: 0,
        startY: 0,
        endX: this.state.editedImage.width - 1,
        endY: this.state.editedImage.height - 1,
      };
    }
  }

  get editedImage(): HatScreenImage {
    return this.state.editedImage;
  }

  get currentPen(): boolean {
    return this.state.currentPen;
  }

  private cutRequested() {
    this.copyRequested();
    this.state.editedImage.drawRect(
      this.currentSelection.startX,
      this.currentSelection.startY,
      this.currentSelection.endX,
      this.currentSelection.endY,
      false,
      true
    );
    this.refreshImage();
  }

  private async copyRequested() {
    const copeidImage = this.state.editedImage.subImage(
      this.currentSelection.startX,
      this.currentSelection.startY,
      this.currentSelection.endX,
      this.currentSelection.endY
    );

    const serializedImage = copeidImage.serializeToJson(
      new JsonSerializationContext()
    );
    const serializedImageJsonStr = JSON.stringify(serializedImage);

    try {
      await navigator.clipboard.write([
        new ClipboardItem(
          {
            [SCREEN_IMAGE_MIME_TYPE]: serializedImageJsonStr,
          },
          {}
        ),
      ]);
    } catch (e) {
      console.warn(
        `Failed to copy to clipboard; using session storage fallback ${e}`
      );
      window.sessionStorage.setItem(
        "IMAGE_CLIPBOARD_FALLBACK",
        serializedImageJsonStr
      );
    }
  }

  private async pasteRequested() {
    let pastedImage: HatScreenImage | null = null;

    try {
      const currentClipboardContent = await navigator.clipboard.read();
      const clipboardItem = currentClipboardContent.find(
        (item) => item.types.indexOf(SCREEN_IMAGE_MIME_TYPE) >= 0
      );
      const blob = await clipboardItem?.getType(SCREEN_IMAGE_MIME_TYPE);
      const arrayBuff = await blob?.arrayBuffer();
      const imageJsonStr = new TextDecoder().decode(arrayBuff);
      const imageJson = JSON.parse(imageJsonStr);
      pastedImage = new HatScreenImage(
        new JsonDeserializationContext(imageJson)
      );
    } catch (e) {
      console.warn(
        `Failed to paste from clipboard; using session storage fallback ${e}`
      );
    }

    if (!pastedImage) {
      try {
        const imageJsonStr = window.sessionStorage.getItem(
          "IMAGE_CLIPBOARD_FALLBACK"
        );
        const imageJson = JSON.parse(imageJsonStr!);
        pastedImage = new HatScreenImage(
          new JsonDeserializationContext(imageJson)
        );
      } catch (e) {
        console.warn(`Failed to paste from session storage: ${e}`);
      }
    }

    if (!pastedImage) {
      return;
    }

    this.setCurrentTool(this._selectTool);
    this._selectTool.pasteImage(pastedImage);
  }

  private setCurrentTool(tool: DrawingTool) {
    if (this.state.currentDrawingTool) {
      this.state.currentDrawingTool.onDeselect();
    }

    if (tool != null) {
      this.setState({ currentDrawingTool: tool });
      tool.onSelect();
    }
  }

  refreshImage() {
    this._imageDisplay.current?.imageToCanvas();
  }

  updatePreviewImage(previewImage: HatScreenImage | null) {
    const imageDisplay = this._imageDisplay.current;
    if (!imageDisplay) {
      return;
    }

    const drawContext = imageDisplay.getDrawContext();
    if (!drawContext) {
      return;
    }

    if (!this._drawingPreviewImage && !previewImage) {
      return;
    }

    if (!this._drawingPreviewImage) {
      this._drawingPreviewImage = new HatScreenImage(
        this.editedImage.width,
        this.editedImage.height
      );
    }

    const effectivePreview =
      previewImage ||
      new HatScreenImage(this.editedImage.width, this.editedImage.height);

    const imWidth = this.editedImage.width;
    const imHeight = this.editedImage.height;
    const pixelSize = imageDisplay.pixelSize;
    const vwFgColor = imageDisplay.fgColor;
    const vwBkColor = imageDisplay.bkColor;

    for (let y = 0; y < imHeight; y++) {
      for (let x = 0; x < imWidth; x++) {
        const newPreview = effectivePreview.get(x, y);
        if (this._drawingPreviewImage.get(x, y) !== newPreview) {
          this._drawingPreviewImage.set(x, y, newPreview);

          drawContext.fillStyle = newPreview
            ? "yellow"
            : this.editedImage.get(x, y)
            ? vwFgColor
            : vwBkColor;
          drawContext.fillRect(
            1 + x * pixelSize,
            1 + y * pixelSize,
            pixelSize - 1,
            pixelSize - 1
          );
        }
      }
    }

    if (!previewImage) {
      this._drawingPreviewImage = null;
    }
  }

  private imageMouseDown(px: number, py: number) {
    if (this.state.currentDrawingTool) {
      this.state.currentDrawingTool.handleMouseDown(px, py);
    }
  }

  private imageMouseMove(px: number, py: number) {
    if (this.state.currentDrawingTool) {
      this.state.currentDrawingTool.handleMouseMove(px, py);
    }
  }

  private imageMouseUp(px: number, py: number) {
    if (this.state.currentDrawingTool) {
      this.state.currentDrawingTool.handleMouseUp(px, py);
    }
  }
}

abstract class DrawingTool {
  constructor(protected _editor: ScreenEditor) {}

  abstract onSelect(): void;
  abstract onDeselect(): void;
  abstract handleMouseDown(imageX: number, imageY: number): void;
  abstract handleMouseMove(imageX: number, imageY: number): void;
  abstract handleMouseUp(imageX: number, imageY: number): void;

  abstract get buttonContent(): ReactNode;
}

class FloodFillDrawingTool extends DrawingTool {
  handleMouseDown(imageX: number, imageY: number): void {
    this._editor.editedImage.floodFill(imageX, imageY, this._editor.currentPen);
    this._editor.refreshImage();
  }

  handleMouseMove(imageX: number, imageY: number): void {}

  handleMouseUp(imageX: number, imageY: number): void {}

  onSelect(): void {}

  onDeselect(): void {}

  get buttonContent(): ReactNode {
    return <ToolIconFloodFill />;
  }
}

abstract class SimplePreviewDrawingTool extends DrawingTool {
  handleMouseDown(imageX: number, imageY: number): void {
    this._previewImage = this._editor.editedImage.createEmptyMaskImage();
  }

  handleMouseUp(imageX: number, imageY: number): void {
    if (this._previewImage) {
      this._editor.updatePreviewImage(null);
      this._editor.editedImage.applyMaskImage(
        this._previewImage,
        this._editor.currentPen
      );
      this._editor.refreshImage();
      this._previewImage = null;
    }
  }

  onSelect(): void {}

  onDeselect(): void {}

  protected _previewImage: HatScreenImage | null = null;
}

class FreeformDrawingTool extends SimplePreviewDrawingTool {
  handleMouseDown(imageX: number, imageY: number): void {
    super.handleMouseDown(imageX, imageY);
    this._previewImage!.set(imageX, imageY, true);
    this._lastX = imageX;
    this._lastY = imageY;
    this._editor.updatePreviewImage(this._previewImage);
  }

  handleMouseMove(imageX: number, imageY: number): void {
    if (this._previewImage) {
      this._previewImage.drawLine(
        this._lastX,
        this._lastY,
        imageX,
        imageY,
        true
      );
      this._lastX = imageX;
      this._lastY = imageY;
      this._editor.updatePreviewImage(this._previewImage);
    }
  }

  get buttonContent(): ReactNode {
    return <ToolIconFreeform />;
  }

  private _lastX: number = 0;
  private _lastY: number = 0;
}

abstract class SimpleDragDrawingTool extends SimplePreviewDrawingTool {
  handleMouseDown(imageX: number, imageY: number): void {
    super.handleMouseDown(imageX, imageY);
    this._startX = imageX;
    this._startY = imageY;
  }

  handleMouseMove(imageX: number, imageY: number): void {
    if (this._previewImage) {
      this._endX = imageX;
      this._endY = imageY;

      this._previewImage = this._editor.editedImage.createEmptyMaskImage();
      this.drawShape(
        this._previewImage,
        this._startX,
        this._startY,
        this._endX,
        this._endY
      );

      this._editor.updatePreviewImage(this._previewImage);
    }
  }

  abstract drawShape(
    previewImage: HatScreenImage,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ): void;

  private _startX = 0;
  private _endX = 0;
  private _startY = 0;
  private _endY = 0;
}

class BoxDrawingTool extends SimpleDragDrawingTool {
  constructor(editor: ScreenEditor, private _fill: boolean) {
    super(editor);
  }

  drawShape(
    previewImage: HatScreenImage,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ): void {
    previewImage.drawRect(
      Math.min(startX, endX),
      Math.min(startY, endY),
      Math.max(startX, endX),
      Math.max(startY, endY),
      true,
      this._fill
    );
  }

  get buttonContent(): ReactNode {
    return this._fill ? <ToolIconBoxF /> : <ToolIconBoxH />;
  }
}

class LineDrawingTool extends SimpleDragDrawingTool {
  drawShape(
    previewImage: HatScreenImage,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ): void {
    previewImage.drawLine(startX, startY, endX, endY, true);
  }

  get buttonContent(): ReactNode {
    return <ToolIconLine />;
  }
}

class EllipseDrawingTool extends SimpleDragDrawingTool {
  constructor(editor: ScreenEditor, private _fill: boolean) {
    super(editor);
  }

  drawShape(
    previewImage: HatScreenImage,
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ): void {
    previewImage.drawEllipse(
      Math.round((startX + endX) / 2),
      Math.round((startY + endY) / 2),
      Math.round(Math.abs(startX - endX) / 2),
      Math.round(Math.abs(startY - endY) / 2),
      true,
      this._fill
    );
  }

  get buttonContent(): ReactNode {
    return this._fill ? <ToolIconEllipseF /> : <ToolIconEllipseH />;
  }
}

class SelectTool extends DrawingTool {
  get buttonContent(): ReactNode {
    return <ToolIconSelect />;
  }

  get selection() {
    return {
      startX: this._startX,
      startY: this._startY,
      endX: this._endX,
      endY: this._endY,
    };
  }

  onSelect(): void {}

  onDeselect(): void {
    this._editor.updatePreviewImage(null);
    this._pastedImage = null;
    this._baseImage = null;
  }

  handleMouseDown(imageX: number, imageY: number): void {
    this._dragging = true;

    const normalizedXS = Math.min(this._startX, this._endX);
    const normalizedYS = Math.min(this._startY, this._endY);
    const normalizedXE = Math.max(this._startX, this._endX);
    const normalizedYE = Math.max(this._startY, this._endY);
    const draggingWithinSelection =
      imageX >= normalizedXS &&
      imageX <= normalizedXE &&
      imageY >= normalizedYS &&
      imageY <= normalizedYE;

    // Exit paste mode if dragging outside of image
    if (this._pastedImage && !draggingWithinSelection) {
      this._pastedImage = null;
    }
    // Acquire paste image if starting to drag selection.
    else if (!this._pastedImage && draggingWithinSelection) {
      this.acquirePastedImageAndCut();
    }

    if (this._pastedImage) {
      this.handlePasteMouseDown(imageX, imageY);
    } else {
      this.handleSelectMouseDown(imageX, imageY);
    }
  }

  handleMouseMove(imageX: number, imageY: number): void {
    if (this._dragging) {
      if (!this._pastedImage) {
        this.handleSelectDrag(imageX, imageY);
      } else {
        this.handlePasteDrag(imageX, imageY);
      }
    }
  }

  handleMouseUp(imageX: number, imageY: number): void {
    this._dragging = false;
    this.normalizeRectCoordinates();
  }

  pasteImage(image: HatScreenImage) {
    // Get pasted image
    this._pastedImage = image;
    this._startX = 0;
    this._startY = 0;
    this._endX = image.width - 1;
    this._endY = image.height - 1;

    // Obtain base image as image post cut
    this._baseImage = this._editor.editedImage.clone();

    // And update pasted image
    this.updatePastedImage();
    this.updateSelectionMarquee();
  }

  private normalizeRectCoordinates(): void {
    const normalizedXS = Math.min(this._startX, this._endX);
    const normalizedYS = Math.min(this._startY, this._endY);
    const normalizedXE = Math.max(this._startX, this._endX);
    const normalizedYE = Math.max(this._startY, this._endY);

    this._startX = normalizedXS;
    this._endX = normalizedXE;

    this._startY = normalizedYS;
    this._endY = normalizedYE;
  }

  private acquirePastedImageAndCut() {
    if (this._startX === this._endX || this._startY === this._endY) {
      return;
    }

    // Get pasted image
    this._pastedImage = this._editor.editedImage.subImage(
      this._startX,
      this._startY,
      this._endX,
      this._endY
    );

    // Cut pasted image
    this._editor.editedImage.drawRect(
      this._startX,
      this._startY,
      this._endX,
      this._endY,
      false,
      true
    );

    // Obtain base image as image post cut
    this._baseImage = this._editor.editedImage.clone();

    // And update pasted image
    this.updatePastedImage();
    this.updateSelectionMarquee();
  }

  private handleSelectMouseDown(imageX: number, imageY: number) {
    this._startX = imageX;
    this._startY = imageY;

    this._endX = imageX;
    this._endY = imageY;

    this.updateSelectionMarquee();
  }

  private handlePasteMouseDown(imageX: number, imageY: number) {
    this._pasteMouseOfsX = imageX - this._startX;
    this._pasteMouseOfsY = imageY - this._startY;
  }

  private handleSelectDrag(imageX: number, imageY: number) {
    // Selection mode
    this._endX = imageX;
    this._endY = imageY;

    this.updateSelectionMarquee();
  }

  private handlePasteDrag(imageX: number, imageY: number) {
    const selWidth = this._endX - this._startX;
    const selHeight = this._endY - this._startY;

    this._startX = imageX - this._pasteMouseOfsX;
    this._startY = imageY - this._pasteMouseOfsY;
    this._endX = this._startX + selWidth;
    this._endY = this._startY + selHeight;

    this.updatePastedImage();
    this.updateSelectionMarquee();
  }

  private updatePastedImage() {
    if (this._baseImage && this._pastedImage) {
      this._editor.editedImage.drawImage(0, 0, this._baseImage);
      this._editor.editedImage.drawImage(
        this._startX,
        this._startY,
        this._pastedImage
      );
    }
    this._editor.updatePreviewImage(null);
    this._editor.refreshImage();
  }

  private updateSelectionMarquee() {
    const previewImage = this._editor.editedImage.createEmptyMaskImage();
    previewImage.drawMarquee(
      Math.min(this._startX, this._endX),
      Math.min(this._startY, this._endY),
      Math.max(this._startX, this._endX),
      Math.max(this._startY, this._endY)
    );
    this._editor.updatePreviewImage(previewImage);
  }

  private _dragging: boolean = false;

  private _baseImage: HatScreenImage | null = null;
  private _pastedImage: HatScreenImage | null = null;

  private _startX: number = -1;
  private _startY: number = -1;

  private _endX: number = -1;
  private _endY: number = -1;

  private _pasteMouseOfsX: number = 0;
  private _pasteMouseOfsY: number = 0;
}

export default ScreenEditor;
