import { CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

type SuspenseHandle = {
  ready: boolean;
};

export type SuspenseProps = {
  suspenseHandle: SuspenseHandle;
  suspenseContent?: React.ReactNode;
  children?: React.ReactNode;
};

export function SuspenseView(props: SuspenseProps) {
  return (
    <>
      {props.suspenseHandle.ready
        ? props.children
        : props.suspenseContent || <StyledCircularProgress />}
    </>
  );
}

export function useSuspense<T>(
  generator: () => Promise<T>,
  deps: any[]
): [result: T | null, suspenseHandler: any, reload: () => void] {
  const [result, setResult] = useState<T | null>(null);
  const [suspenseHandler, setSuspenseHandler] = useState<SuspenseHandle>({
    ready: false,
  });

  const loadData = useCallback(async () => {
    setResult(await generator());
    setSuspenseHandler({ ready: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setResult, setSuspenseHandler]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, loadData]);

  const reload = () => {
    setResult(null);
    setSuspenseHandler({ ready: false });
    loadData();
  };

  return [result, suspenseHandler, reload];
}

const StyledCircularProgress = styled(CircularProgress)`
  margin: 64px auto;
`;
