import { useCallback, useMemo, useRef, useState } from "react";

export type DialogStandardInputs<DI, DO> = DI & {
  onClose(returnValue?: DO): void;
};

export function useDialog<DI extends object, DO extends object>(
  dialogGenerator: (input: DialogStandardInputs<DI, DO>) => React.ReactNode
) {
  const [latestProps, setLatestProps] = useState<DI | null>(null);
  const acceptHolder = useRef<((returnValue?: DO) => void) | null>();

  const onClose = useCallback(
    (returnValue?: DO) => {
      acceptHolder.current!(returnValue);
      setLatestProps(null);
    },
    [setLatestProps, acceptHolder]
  );

  const showModal = useCallback(
    (inputs: DI) => {
      const returnedPromise = new Promise<DO | null>((acceptPromise) => {
        acceptHolder.current = (returnValue) =>
          acceptPromise(returnValue || null);
      });

      setLatestProps(inputs);
      return returnedPromise;
    },
    [setLatestProps, acceptHolder]
  );

  const dialogComponent = useMemo(
    () => <>{latestProps && dialogGenerator({ ...latestProps, onClose })}</>,
    [latestProps, dialogGenerator, onClose]
  );

  return { showModal, dialogComponent };
}
