import { ReactNode } from "react";

export interface TabPanelProps {
    value: string;
    index: string;
    children: ReactNode;
}
  
export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          children        
        )}
      </div>
    );
  }