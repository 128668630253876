import { Brightness6, Palette, Traffic, Tungsten, RadioButtonChecked, RemoveRedEye, Tv } from "@mui/icons-material"

import { ControlConnectionType, HatRetroControlConfigurationSchemaInformation, HatRetroControlConfigurationWithTypeTag, HatRetroControlTypeTag } from "hat-common"
import { ControlSettingsEditorMultiLed } from "./ControlSettingsEditorMultiLed"
import { ControlSettingsEditorLedStrip } from "./ControlSettingsEditorLedStrip"
import { ControlSettingsEditorTimer } from "./ControlSettingsEditorTimer"
import { ControlSettingsEditorPwmEditor } from "./ControlSettingsEditorPwmLed"

type ControlEditingSupportBindingInfo = {
	displayName: string
}

type ControlPropertiesEditorPropsForType<TConfigType> = {
	controlConfiguration: TConfigType
	onControlConfigurationChanged: (newSettings: TConfigType) => void
}

export type ControlPropertiesEditorProps<TSpecificTag extends HatRetroControlTypeTag> = ControlPropertiesEditorPropsForType<HatRetroControlConfigurationWithTypeTag<TSpecificTag>>

export type UntypedControlPropertiesEditorProps = ControlPropertiesEditorPropsForType<any>

type ControlEditingSupportForTag<ControlTag extends HatRetroControlTypeTag> = {
	displayName: string
	icon: (props: object) => JSX.Element | null
	bindings: {
		[bindingPropertyName in keyof (typeof HatRetroControlConfigurationSchemaInformation)[ControlTag]["bindings"]]: ControlEditingSupportBindingInfo
	}
	categories: ControlCategory[]
	propertiesEditor?: (props: ControlPropertiesEditorProps<ControlTag>) => JSX.Element | null
}

type UntypedControlPropertiesEditor = (props: UntypedControlPropertiesEditorProps) => JSX.Element | null

export const ControlCategories = {
	controls: { displayName: "Controls", displayIndex: 0 },
	helpers: { displayName: "Helpers", displayIndex: 1 },
} satisfies {
	[categoryName: string]: {
		displayIndex: number
		displayName: string
	}
}

export type ControlCategory = keyof typeof ControlCategories

export type ControlEditingSupport = {
	displayName: string
	icon: (props: object) => JSX.Element | null
	bindings: Record<string, ControlEditingSupportBindingInfo>
	categories: ControlCategory[]
	propertiesEditor?: UntypedControlPropertiesEditor
}

export const ControlEditingSupportList: {
	[typeTag in HatRetroControlTypeTag]: ControlEditingSupport
} = {
	switch: {
		displayName: "Switch",
		icon: () => <RadioButtonChecked fontSize="large" />,
		bindings: {
			binding: { displayName: "When pressed" },
			onchange: { displayName: "On state changed" },
		},
		categories: ["controls"],
	},

	pot: {
		displayName: "Potentiometer",
		icon: () => <RadioButtonChecked fontSize="large" />,
		bindings: {
			binding: { displayName: "When operated" },
		},
		categories: ["controls"],
	},
	ther: {
		displayName: "Thermistor",
		icon: () => <RadioButtonChecked fontSize="large" />,
		bindings: {
			binding: { displayName: "When operated" },
		},
		categories: ["controls"],
	},
	led: {
		displayName: "LED",
		icon: () => <Tungsten fontSize="large" />,
		bindings: {
			binding: { displayName: "LED state" },
		},
		categories: ["controls"],
	},

	pwmLed: {
		displayName: "Brightness Controlled LED",
		icon: () => <Brightness6 fontSize="large" />,
		bindings: {
			binding: { displayName: "LED brightness" },
		},
		categories: ["controls"],
		propertiesEditor: ControlSettingsEditorPwmEditor,
	},

	rgbw_mono: {
		displayName: "RGB Led Strip",
		icon: () => <Palette fontSize="large" />,
		bindings: {
			blueBinding: { displayName: "Blue level" },
			redBinding: { displayName: "Red level" },
			greenBinding: { displayName: "Green level" },
			whiteBinding: { displayName: "White level" },
		},
		propertiesEditor: ControlSettingsEditorLedStrip,
		categories: ["controls"],
	},

	MAX7219: {
		displayName: "MAX7219 Display",
		icon: () => <Tv fontSize="large" />,
		bindings: {
			binding: { displayName: "Display text" },
		},
		categories: ["controls"],
	},
	LiquidCrystal: {
		displayName: "LCD Display",
		icon: () => <Tv fontSize="large" />,
		bindings: {
			binding: { displayName: "Display text" },
		},
		categories: ["controls"],
	},
	multi_led: {
		displayName: "Multi LED",
		icon: () => <Traffic fontSize="large" />,
		bindings: { binding: { displayName: "Display Value" } },
		propertiesEditor: ControlSettingsEditorMultiLed,
		categories: ["controls"],
	},

	ob: {
		displayName: "Observer",
		icon: () => <RemoveRedEye fontSize="large" />,
		bindings: {
			sub: {
				displayName: "Observed Value",
			},
			cmd: {
				displayName: "On change",
			},
		},
		categories: ["helpers"],
	},

	tm: {
		displayName: "Timer",
		icon: () => <RemoveRedEye fontSize="large" />,
		bindings: { cmd: { displayName: "When fires" } },
		propertiesEditor: ControlSettingsEditorTimer,
		categories: ["helpers"],
	},
} satisfies {
	[typeTag in HatRetroControlTypeTag]: ControlEditingSupportForTag<typeTag>
}

export const ConnectionTypeColors: {
	[connectionType in ControlConnectionType]: string
} = {
	[ControlConnectionType.AdressableRgbLed]: "#304d63",
	[ControlConnectionType.AnalogIn]: "#b2e7e8",
	[ControlConnectionType.DigitalIn]: "#8fb9aa",
	[ControlConnectionType.DigitalOut]: "#f2d096",
	[ControlConnectionType.Pwm]: "#ed8975",
}
