import { CompanionClassAssociation } from "hat-common";
import { HatMenuCommand } from "hat-common/lib/HatMenuCommand";
import { ReactNode } from "react";

export interface CommandEditingSupport {
  title: string;
  createCommand(): HatMenuCommand;
  renderEditor(
    command: HatMenuCommand,
    onUpdate: (updatedCommand: HatMenuCommand) => void
  ): ReactNode;
  describe(command: HatMenuCommand): string;
}

export const AssociatedCommandEditingSupport = new CompanionClassAssociation<
  HatMenuCommand,
  CommandEditingSupport,
  [systemId: string]
>();

export interface CommandFactory {
  commandKlass: new (...a: any) => HatMenuCommand;
  title: string;
  createCommand: () => HatMenuCommand;
}

let commandFactories: CommandFactory[] | null = null;

export function getCommandFactories(): CommandFactory[] {
  if (!commandFactories) {
    commandFactories = [];

    let menuCommandClass: new (...a: any) => HatMenuCommand;
    for (menuCommandClass of AssociatedCommandEditingSupport.getRegisteredSources()) {
      const editingSupport = AssociatedCommandEditingSupport.createForClass(
        menuCommandClass,
        ""
      );
      commandFactories.push({
        commandKlass: menuCommandClass,
        title: editingSupport.title,
        createCommand: () => editingSupport.createCommand(),
      });
    }
  }

  return commandFactories;
}
