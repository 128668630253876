import { Switch, TextField, Alert, AlertColor, Snackbar } from "@mui/material"
import { HatDeviceConfiguration, HatRetroConsoleMqttConfig, HatRetroControllerConfiguration } from "hat-common"
import { useCallback, useContext, useState } from "react"
import { usePropertiesUpdater } from "../../../utilities/hooks"
import { FieldAndLabelRow, FieldLabelDiv, IndentedContainer, SpacedVerticalStack } from "../../common/CommonStyled"
import { MqttConnectionConfigEditor } from "./common-config-fragment-editors/MqttConnnectionConfigEditor"
import { OptionalNetworkLoggingConfigEditor } from "./common-config-fragment-editors/NetworkLoggingConfigEditor"
import { OptionalValueEditor, ValueEditorProps } from "./common-config-fragment-editors/OptionalValueEditor"
import "./RetroControllerDeviceSettingsEditor.css"
import { apiClient } from "../../../utilities/api-client"
import { UploadButton } from "../../common/UploadButton"
import { useParams } from "react-router-dom"

export interface RetroControllerDeviceSettingsEditorProps {
	device: HatRetroControllerConfiguration
	onDeviceUpdated: (device: HatRetroControllerConfiguration) => void
}

export function RetroControllerDeviceSettingsEditor({ device, onDeviceUpdated }: RetroControllerDeviceSettingsEditorProps) {
	const { deviceId } = useParams()
	const { systemId } = useParams()
	const [alertMessage, setAlertMessage] = useState<string>()
	const [alertSeverity, setAlertSeverity] = useState<AlertColor>()

	const updateDevice = useCallback(
		(deviceUpdater: (d: HatRetroControllerConfiguration) => void) => {
			const newDevice = device.clone() as HatRetroControllerConfiguration
			deviceUpdater(newDevice)
			onDeviceUpdated(newDevice)
		},
		[device, onDeviceUpdated]
	)

	const showAlert = useCallback(
		(severity: AlertColor, message: string) => {
			setAlertSeverity(severity)
			setAlertMessage(message)
		},
		[setAlertMessage, setAlertSeverity]
	)

	async function HandleFileUpload(file: File) {
		const formData = new FormData()
		formData.append("file", file)
		formData.append("systemId", systemId ?? "")
		formData.append("deviceId", deviceId ?? "")
		try {
			await apiClient.invokePostApi("system/uploadFirmware", formData)
			showAlert("success", "Firmware uploaded sucessfuly.")
		} catch (e: any) {
			showAlert("error", "Error uploading file.")
		}
	}

	return (
		<div className="deviceSettingsForm">
			<SpacedVerticalStack>
				<OptionalNetworkLoggingConfigEditor editedValue={device.netLoggingConfig} onEditedValueChanged={(newValue) => updateDevice((d) => (d.netLoggingConfig = newValue))} />
				<FieldAndLabelRow>
					<Switch checked={device.webserver} onChange={(event) => updateDevice((d) => (d.webserver = event.target.checked))} />
					Enable Web Server
				</FieldAndLabelRow>{" "}
				<OptionalValueEditor<HatRetroConsoleMqttConfig>
					title="Enable MQTT connection"
					editedValue={device.mqttConnectionConfig}
					onEditedValueChanged={(newValue) => updateDevice((d) => (d.mqttConnectionConfig = newValue))}
					defaultValueFactory={() => ({
						conection: {
							server: "localhost",
							port: 1883,
							username: "",
							password: "",
						},
						defaultPublishingTopic: "AE_C3_events",
						subscriptionTopic: "AE_C3_events",
					})}
					valueEditor={HatRetroConsoleMqttConfigEditor}
				/>
				<FieldAndLabelRow>Hardware Settings</FieldAndLabelRow>
				<IndentedContainer>
					<SpacedVerticalStack>
						<FieldAndLabelRow>
							<Switch checked={device.pcaConstantSource} onChange={(event) => updateDevice((d) => (d.pcaConstantSource = event.target.checked))} />
							PCA Constant Source
						</FieldAndLabelRow>
						<FieldAndLabelRow>
							<Switch checked={device.tcaConstantSource} onChange={(event) => updateDevice((d) => (d.tcaConstantSource = event.target.checked))} />
							TCA Constant Source
						</FieldAndLabelRow>
					</SpacedVerticalStack>
				</IndentedContainer>
				<FieldAndLabelRow>
					<FieldLabelDiv>Debug Level:</FieldLabelDiv> <TextField size="small" variant="filled" type="number" inputProps={{ min: 0, max: 6 }} value={device.debugLevel} onChange={(event) => updateDevice((d) => (d.debugLevel = parseInt(event.target.value)))} />
				</FieldAndLabelRow>
				<FieldAndLabelRow> </FieldAndLabelRow>
				<FieldAndLabelRow>Over-the-Air Firmware Updates</FieldAndLabelRow>
				<IndentedContainer>
					<SpacedVerticalStack>
						<FieldAndLabelRow>
							<FieldLabelDiv>Target firmware version:</FieldLabelDiv>
							<TextField size="small" variant="filled" type="number" value={device.targetFWVersion} onChange={(event) => updateDevice((d) => (d.targetFWVersion = parseInt(event.target.value)))} />
						</FieldAndLabelRow>
						<FieldAndLabelRow>
							<FieldLabelDiv>Donwload server:</FieldLabelDiv>
							<TextField size="small" type="any" label="https://example.com" variant="filled" value={device.targetFWVersionDownloadUrl} onChange={(event) => updateDevice((d) => (d.targetFWVersionDownloadUrl = event.target.value))} />
						</FieldAndLabelRow>
						<FieldAndLabelRow>
							<UploadButton label="Upload Firmware" onFileUpload={HandleFileUpload}></UploadButton>
						</FieldAndLabelRow>
					</SpacedVerticalStack>
				</IndentedContainer>
			</SpacedVerticalStack>
			<Snackbar open={Boolean(alertMessage)} autoHideDuration={5000} onClose={() => setAlertMessage(undefined)}>
				<Alert onClose={() => setAlertMessage(undefined)} severity={alertSeverity}>
					{alertMessage}
				</Alert>
			</Snackbar>
		</div>
	)
}

function HatRetroConsoleMqttConfigEditor({ editedValue, onEditedValueChanged }: ValueEditorProps<HatRetroConsoleMqttConfig>) {
	const updateProperties = usePropertiesUpdater(editedValue, onEditedValueChanged)

	return (
		<>
			<MqttConnectionConfigEditor editedValue={editedValue.conection} onEditedValueChanged={(newConnection) => updateProperties({ conection: newConnection })} />

			<FieldAndLabelRow>
				<FieldLabelDiv>MQTT Topics:</FieldLabelDiv>
				<SpacedVerticalStack>
					<TextField size="small" variant="filled" label="Subscription topic" value={editedValue.subscriptionTopic} onChange={(event) => updateProperties({ subscriptionTopic: event.target.value })} />
					<TextField size="small" variant="filled" label="Default publish topic" value={editedValue.defaultPublishingTopic} onChange={(event) => updateProperties({ defaultPublishingTopic: event.target.value })} />
				</SpacedVerticalStack>
			</FieldAndLabelRow>
		</>
	)
}

/*debugLevel: number;
pcaConstantSource: boolean;
tcaConstantSource: boolean;*/
