import { ControlConnectionType } from "hat-common";

export type BoardTopology = {
  topologyName: string;
  pins: BoardTopologyPinDescriptor[];
  outletsAnchor: "topLeft" | "topRight" | "bottomRight" | "bottomLeft";
};

export type BoardTopologyPinDescriptor = {
  pinTypes: ControlConnectionType[];
};

export const RetroBoardTopology: BoardTopology = {
  topologyName: "retro-console",
  outletsAnchor: "topLeft",
  pins: [
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [] },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [] },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.Pwm,
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
        ControlConnectionType.AdressableRgbLed,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    {
      pinTypes: [
        ControlConnectionType.DigitalOut,
        ControlConnectionType.DigitalIn,
      ],
    },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },
    { pinTypes: [ControlConnectionType.Pwm] },

    { pinTypes: [ControlConnectionType.AnalogIn] },
    { pinTypes: [ControlConnectionType.AnalogIn] },
    { pinTypes: [ControlConnectionType.AnalogIn] },
    { pinTypes: [ControlConnectionType.AnalogIn] },
  ],
};
