import { useCallback } from "react";

export function usePropertiesUpdater<TValue>(
  currentValue: TValue,
  onValueUpdate: (newValue: TValue) => void
) {
  return useCallback(
    (updatedProps: Partial<TValue>) => {
      onValueUpdate({ ...currentValue, ...updatedProps });
    },
    [currentValue, onValueUpdate]
  );
}
