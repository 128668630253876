import { Checkbox, FormControlLabel, Radio, TextField } from "@mui/material";
import { DeviceInactivityConfig, HatMenuDeviceConfiguration } from "hat-common";
import { ChangeEvent } from "react";
import "./MenuDeviceSettingsEditor.css";

export interface DeviceSettingsEditorProps {
  device: HatMenuDeviceConfiguration;
  onDeviceUpdated: (device: HatMenuDeviceConfiguration) => void;
}

export function MenuDeviceSettingsEditor(props: DeviceSettingsEditorProps) {
  const updateInactivityConfig = (
    transformer: (
      cfg: DeviceInactivityConfig | null
    ) => DeviceInactivityConfig | null
  ) => {
    const newConfig = transformer(
      props.device.inactivityConfig &&
        Object.assign({}, props.device.inactivityConfig)
    );
    const newDev = props.device.withUpdatedInactivityConfig(newConfig);

    if (props.onDeviceUpdated) {
      props.onDeviceUpdated(newDev);
    }
  };

  const onInactivitySwitchChanged = (_: any, checked: boolean) => {
    updateInactivityConfig((cfg) => {
      if (checked) {
        return { inactivityTimeoutMillis: 30000, inactivityAction: "dim" };
      } else {
        return null;
      }
    });
  };

  const onInactivityTimeChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    updateInactivityConfig((cfg) => {
      if (cfg) {
        cfg.inactivityTimeoutMillis = evt.target.valueAsNumber * 1000;
      }

      return cfg;
    });
  };

  const setInactivityAction = (
    action: DeviceInactivityConfig["inactivityAction"]
  ) => {
    updateInactivityConfig((cfg) => {
      if (cfg) {
        cfg.inactivityAction = action;
      }

      return cfg;
    });
  };

  return (
    <div className="deviceSettingsForm">
      <FormControlLabel
        control={
          <Checkbox
            checked={!!props.device.inactivityConfig}
            onChange={onInactivitySwitchChanged}
          />
        }
        label="When inactive for "
      />
      <TextField
        hiddenLabel
        size="small"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        value={
          (props.device.inactivityConfig?.inactivityTimeoutMillis || 0) / 1000
        }
        onChange={onInactivityTimeChanged}
      />
      &nbsp; seconds,
      <Radio
        checked={props.device.inactivityConfig?.inactivityAction === "dim"}
        onChange={() => setInactivityAction("dim")}
        name="inactivity-action"
      />{" "}
      dim screen <i>or</i>
      <Radio
        checked={props.device.inactivityConfig?.inactivityAction === "darken"}
        onChange={() => setInactivityAction("darken")}
        name="inactivity-action"
      />{" "}
      turn screen off
    </div>
  );
}
