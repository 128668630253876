import { HatMenuCommand } from "hat-common/lib/HatMenuCommand";
import { useState } from "react";

export interface CommandDetailsEditorProps<T extends HatMenuCommand> {    
    systemId: string;
    command: T;
    onUpdate?: (newCommand: HatMenuCommand) => void;
}

type CommandEditorHelpers<T> = [
    command: T, 
    updateCommand: (updater: (command: T) => void) => void
];

export function useCommandEditorHelpers<T extends HatMenuCommand>(
    props: CommandDetailsEditorProps<T>) : CommandEditorHelpers<T> {
    const [command, setCommand] = useState(props.command);

    const updateCommand = (updater: (cmd: T) => void) => {
        const newCommand = command.clone() as T;
        updater(newCommand);
        setCommand(newCommand);
        if(props.onUpdate) {
            props.onUpdate(newCommand);
        }
    }

    return [command, updateCommand];                                            
}
