import { TextField } from "@mui/material";
import {
  DeviceConnectionCloudHomeAssistant,
  JsonSerializationContext,
} from "hat-common";
import { ReactNode, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { apiClient } from "../../../../utilities/api-client";
import { AppSystemContext } from "../../../../utilities/app-system-context";
import {
  AssociatedConnectedDeviceEditingSupport,
  ConnectedDeviceEditingSupport,
  ConnectionEditorProperties,
  ConnectionSetupProperties,
} from "./ConnectedDeviceEditingSupport";

@AssociatedConnectedDeviceEditingSupport.For(DeviceConnectionCloudHomeAssistant)
export class HomeAssistantCloudConnectionEditingSupport extends ConnectedDeviceEditingSupport {
  createSetupUi(props: ConnectionSetupProperties): ReactNode {
    return HomeAssistantCloudSetupUi(props);
  }

  createEditor(props: ConnectionEditorProperties): ReactNode {
    return HomeAssistantCloudConnectionEditor(props);
  }

  get displayName(): string {
    return "Home Assistant Push";
  }

  get iconName(): string {
    return "HomeAssistant";
  }
}

function HomeAssistantCloudConnectionEditor(props: ConnectionEditorProperties) {
  const haCloudConnection = props.value as DeviceConnectionCloudHomeAssistant;

  return (
    <>
      {props.status.connectionStatus === "pending" && (
        <ConnectionInstructions {...props} />
      )}

      <h2>Connection Settings</h2>
      <ConnectionSettingsEditor
        value={haCloudConnection}
        onChange={props.setValue}
      />
    </>
  );
}

function ConnectionInstructions(props: ConnectionEditorProperties) {
  const haCloudConnection = props.value as DeviceConnectionCloudHomeAssistant;
  const systemContext = useContext(AppSystemContext);

  return (
    <>
      <h2>Connect your Home Assistant server</h2>
      To finish connecting your Home Assistant Push integration, follow these
      steps:
      <ol>
        <li>
          Download the <b>hatha_sync</b> custom component, and unzip its content
          to your Home Assistant custom components folder. This is typically a
          folder called <b>custom_components</b>
          located in the same directory where your Home Assistant configuration
          is.
        </li>
        <li>
          <p>
            Use the button below to add setup the Hatha integration in your Home
            Assistant instance.{" "}
          </p>

          <p>
            <a
              href="https://my.home-assistant.io/redirect/config_flow_start/?domain=hatha"
              target="_blank"
            >
              <img
                src="https://my.home-assistant.io/badges/config_flow_start.svg"
                alt="Open your Home Assistant instance and start setting up a new integration."
              />
            </a>
          </p>

          <p>
            If you prefer doing this manually, from the Home Assistant UI,
            select &nbsp;
            <b>Configuration</b> | <b>Devices &amp; Services</b>. Then select
            the <b>Integrations</b> tab, and click the <b>Add Integration</b>{" "}
            button at the bottom right. From the integration list select{" "}
            <b>hatha</b>.
          </p>
        </li>
        <li>
          Configure the following settings in the component:
          <ConnectionConfigPanel>
            <div>
              HAT Push Device Token: <b>{haCloudConnection.syncToken}</b>
            </div>
            <div>
              HAT System ID: <b>{systemContext.systemId}</b>
            </div>
          </ConnectionConfigPanel>
        </li>
      </ol>
    </>
  );
}
const ConnectionConfigPanel = styled.div`
  width: 50%;

  padding: 12px;
  margin: auto;
  margin-top: 24px;

  border: 1px solid #d5d5d5;
  border-radius: 6px;
  background: #f8f8f8;
`;

function HomeAssistantCloudSetupUi(props: ConnectionSetupProperties) {
  const systemContext = useContext(AppSystemContext);

  const [connectionTemplate, setConnectionTemplate] = useState(
    new DeviceConnectionCloudHomeAssistant("", "", "<template>")
  );

  useEffect(() => {
    props.onSetCreationCompletionCall(async () => {
      const createConnectionResult = await apiClient.invokePostApi(
        "system/create-device-connection-by-template",
        {
          systemId: systemContext.systemId,
          connectionConfigTemplate: new JsonSerializationContext().writeObject(
            connectionTemplate
          ),
        }
      );

      return createConnectionResult.data.createdId;
    });
  }, [connectionTemplate, props, systemContext.systemId]);

  return (
    <ConnectionSettingsEditor
      value={connectionTemplate}
      onChange={(conn) => setConnectionTemplate(conn)}
    />
  );
}

function ConnectionSettingsEditor({
  value,
  onChange,
}: {
  value: DeviceConnectionCloudHomeAssistant;
  onChange: (newValue: DeviceConnectionCloudHomeAssistant) => void;
}) {
  const updateConnection = (updatedAttributes: any) => {
    const newConn = value.clone() as DeviceConnectionCloudHomeAssistant;
    onChange(Object.assign(newConn, updatedAttributes));
  };

  return (
    <>
      <ConnectionSettingsForm>
        <StyledTextField
          variant="standard"
          label="Connection Name"
          value={value.name}
          onChange={(event) => updateConnection({ name: event.target.value })}
        />

        <StyledTextField
          variant="standard"
          label="HomeAssistant Local URL"
          value={value.localNetworkUrl}
          onChange={(event) =>
            updateConnection({ localNetworkUrl: event.target.value })
          }
        />
      </ConnectionSettingsForm>
    </>
  );
}
const StyledTextField = styled(TextField)`
  width: 100%;
`;

const ConnectionSettingsForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${StyledTextField} {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
