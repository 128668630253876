import { HatMenuCommand, HvacControlMenuCommand } from "hat-common/lib/HatMenuCommand";
import { CommandDetailsEditorProps, useCommandEditorHelpers } from "./CommandDetailsEditorHelper";
import './CommandEditorDeviceHvacControl.css';
import { DeviceSelector } from "../DeviceSelector";
import { AssociatedCommandEditingSupport, CommandEditingSupport } from "./CommandEditingSupport";
import { ReactNode } from "react";
import { humanizeDeviceName } from "../../../../utilities/device-descriptors-cache";

export type CommandEditorDeviceHvacControlProps = 
    CommandDetailsEditorProps<HvacControlMenuCommand>;

export function CommandEditorDeviceHvacControl(props: CommandEditorDeviceHvacControlProps) {
    const [command, updateCommand] = useCommandEditorHelpers(props);

    return (
        <div className="hvacCommandParams">
            <DeviceSelector 
                systemId={props.systemId}
                selectedDevice={command.deviceName}
                onUpdate={(device) => updateCommand((cmd) => cmd.deviceName = device)}
                deviceType="hvac" />
        </div>
    )
}


@AssociatedCommandEditingSupport.For(HvacControlMenuCommand)
export class CommandEditingSupportDeviceHvacControl implements CommandEditingSupport {
    constructor(private systemId: string) {

    }

    title: string = "HVAC Panel";

    createCommand(): HatMenuCommand {
        return new HvacControlMenuCommand('');
    }

    renderEditor(command: HatMenuCommand, 
                 onUpdate: (updatedCommand: HatMenuCommand) => void): ReactNode {
        return (
            <CommandEditorDeviceHvacControl 
                systemId={this.systemId}
                command={command as HvacControlMenuCommand}
                onUpdate={onUpdate}></CommandEditorDeviceHvacControl>);
    }   

    describe(command: HatMenuCommand): string {
        const humanizedDeviceName = 
            humanizeDeviceName(this.systemId, (command as HvacControlMenuCommand).deviceName);
        return `Control HVAC '${humanizedDeviceName}'`;
    }
}