import SaveIcon from '@mui/icons-material/Save';
import { Alert, Button, IconButton } from "@mui/material";
import { DeviceConnectionConfig, JsonDeserializationContext, JsonSerializationContext } from "hat-common";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { apiClient } from "../../../utilities/api-client";
import { AppSystemContext } from "../../../utilities/app-system-context";
import { ScreenTitleBar } from "../../common/ScreenTitleBar";
import { AssociatedConnectedDeviceEditingSupport } from "./integration-editors/ConnectedDeviceEditingSupport";
import { FormPageBody } from '../../common/FormPageBody';

export function ConnectedDeviceEditor({ editedDeviceId } : { editedDeviceId: string }) {
  const [editedDevice, setEditedDevice] = useState<DeviceConnectionConfig | null>();
  const [deviceStatus, setDeviceStatus] = useState<any>();
  const [dirty, setDirty] = useState<boolean>(false);
  const systemContext = useContext(AppSystemContext);

  const loadConnectedDeviceConfig = useCallback(async () => {      
    const [deviceConfigJson, deviceStatusJson] = await Promise.all([
      apiClient.invokeGetApi("system/connected-device-config", {
        "s": systemContext.systemId,
        "c": editedDeviceId
      }),
      
      apiClient.invokeGetApi("system/connected-device-status", {
        "s": systemContext.systemId,
        "c": editedDeviceId
      })
    ]);;

    const deserContext = new JsonDeserializationContext(deviceConfigJson.data);

    setEditedDevice(deserContext.readObject(DeviceConnectionConfig));
    setDeviceStatus(deviceStatusJson.data);
    setDirty(false);
  }, [editedDeviceId, systemContext.systemId]);

  const updateEditedDevice = (device: DeviceConnectionConfig) => {
    setEditedDevice(device);
    setDirty(true);
  }

  const saveConnectedDeviceConfig = async () => {
    await apiClient.invokePostApi("system/connected-device-config", {
      systemId: systemContext.systemId,
      connectionId: editedDevice?.id,
      connectionConfig: new JsonSerializationContext().writeObject(editedDevice!),
    });

    await loadConnectedDeviceConfig();    
  };

  useEffect(() => {
    loadConnectedDeviceConfig()
  }, [editedDeviceId, loadConnectedDeviceConfig]);

  const editingSupport = useMemo(() => 
    editedDevice && AssociatedConnectedDeviceEditingSupport.createFor(editedDevice, systemContext.systemId), 
    [editedDevice, systemContext.systemId])

  const enableDevice = useCallback(async () => {
    try {
      await apiClient.setConnectionEnabled(systemContext.systemId, editedDevice!.id, true);
    } finally {
      await loadConnectedDeviceConfig();
    }
  }, [editedDevice, loadConnectedDeviceConfig, systemContext.systemId]);

  return <>
    <ScreenTitleBar>
      <ScreenTitleBar.BreadCrumbs>
        <Link to="../integrations">Integrations</Link>
      </ScreenTitleBar.BreadCrumbs>

      <ScreenTitleBar.TitleText>
        {editedDevice?.name}
      </ScreenTitleBar.TitleText>

      <ScreenTitleBar.ActionBar>               
        <IconButton 
          color="primary" 
          disabled={!dirty}
          onClick={() => saveConnectedDeviceConfig()}>
            <SaveIcon/>
        </IconButton>        
      </ScreenTitleBar.ActionBar>
    </ScreenTitleBar>

    {editedDevice && deviceStatus && editingSupport && 
      <FormPageBody>
          {!editedDevice.enabled ? 
            <Alert severity="warning" action={<Button onClick={enableDevice}>Enable</Button>}>Connection is disabled.</Alert> :
           deviceStatus.connectionStatus === 'pending' ?
           <Alert severity="warning">Device connection is not ready.</Alert> :    
           deviceStatus.connectionStatus === 'success' ?
            <Alert severity="success">Connected; last seen at {deviceStatus.statusTime}.</Alert> :    
           deviceStatus.connectionStatus === 'error' ?
            <Alert severity="error">Error occurred at {deviceStatus.lastErrorTime}: {deviceStatus.lastError}.</Alert> :
            <></>}

        {editingSupport.createEditor({ value: editedDevice, status: deviceStatus, setValue: updateEditedDevice })}
      </FormPageBody>}

  </>;
}