import { DeviceDescriptor } from "hat-common";
import { apiClient } from "./api-client";

let lastLoadedDeviceDescriptors: {
    [systemName: string]: DeviceDescriptor[] 
} = {};

export async function getAvailableDeviceDescriptors(systemId: string): Promise<DeviceDescriptor[]> {
    if(!lastLoadedDeviceDescriptors[systemId]) {

        lastLoadedDeviceDescriptors[systemId] = [];

        const rt = await apiClient.invokeGetApi("system/connected-device-descriptors", {
            "s": systemId
          });

        lastLoadedDeviceDescriptors[systemId] = rt.data.descriptors;
    }
    
    return lastLoadedDeviceDescriptors[systemId];
}

export function humanizeDeviceName(systemId: string, deviceName: string): string {
    // Trigger caching for device descriptors
    getAvailableDeviceDescriptors(systemId);

    const systemDescriptors = lastLoadedDeviceDescriptors[systemId];
    return systemDescriptors.find((d) => d.fullName === deviceName)?.displayName || 
            deviceName;
}
