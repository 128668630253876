import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import {
  GetHatRetroControlConfigurationBindingsBlockly,
  GetHatRetroControlConfigurationDynamicSchemaInformation,
  HatDeviceConfiguration,
  HatRetroConsoleControlFactory,
  HatRetroControlConfiguration,
  HatRetroControlConfigurationSchemaInformationType,
  HatRetroControllerConfiguration,
  HatRetroControlTypeTag,
} from "hat-common";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { DialogStandardInputs, useDialog } from "../../../utilities/use-dialog";
import {
  HorizontalStack,
  HorizontalStackItemSeparator,
} from "../../common/CommonStyled";
import { InlineEditableLabel } from "../../common/InlineEditableLabel";
import { MenuButton } from "../../common/MenuButton";
import {
  BoardTopology,
  RetroBoardTopology,
} from "./control-editing/BoardEditingSupport";
import { ControlBindingsEditor } from "./control-editing/ControlBindingsEditor";
import {
  ConnectionToPin,
  ControlConnectionsEditor,
} from "./control-editing/ControlConnectionsEditor";
import {
  ControlCategory,
  ControlEditingSupport,
  ControlEditingSupportList,
} from "./control-editing/ControlEditingSupport";

type DeviceControlListEditorProps = {
  device: HatRetroControllerConfiguration;
  onDeviceUpdated: (device: HatDeviceConfiguration) => void;
  controlCategory: ControlCategory;
};

export function DeviceControlListEditor({
  device,
  onDeviceUpdated,
  controlCategory,
}: DeviceControlListEditorProps) {
  const deleteControl = useCallback(
    (index) => {
      const updatedDevice = device.clone() as HatRetroControllerConfiguration;
      updatedDevice.controlConfigurations.splice(index, 1);

      onDeviceUpdated(updatedDevice);
    },
    [device, onDeviceUpdated]
  );

  const addNewControl = useCallback(
    (typeTag: HatRetroControlTypeTag) => {
      const updatedDevice = device.clone() as HatRetroControllerConfiguration;
      updatedDevice.controlConfigurations.push(
        HatRetroConsoleControlFactory[typeTag].createDefault()
      );

      onDeviceUpdated(updatedDevice);
    },
    [onDeviceUpdated, device]
  );

  const updateControlConfigurationName = useCallback(
    (index: number, newName: string) => {
      const updatedDevice = device.clone() as HatRetroControllerConfiguration;
      updatedDevice.controlConfigurations[index].name = newName;
      onDeviceUpdated(updatedDevice);
    },
    [device, onDeviceUpdated]
  );

  const {
    showModal: showBindingsEditor,
    dialogComponent: bindingsEditorDialog,
  } = useDialog<BindingEditorDialogInput, BindingEditorDialogOutput>(
    (input) => <BindingEditorDialog {...input} />
  );

  const {
    showModal: showConnectionsEditor,
    dialogComponent: connectionsEditorDialog,
  } = useDialog<ConnectionEditorDialogInput, ConnectionEditorDialogOutput>(
    (input) => <ConnectionEditorDialog {...input} />
  );

  const {
    showModal: showPropertiesEditor,
    dialogComponent: propertiesEditorDialog,
  } = useDialog<PropertiesEditorDialogInput, PropertiesEditorDialogOutput>(
    (input) => <PropertiesEditorDialog {...input} />
  );

  const handleEditBindings = useCallback(
    async (controlConfiguration: HatRetroControlConfiguration) => {
      const controlConfigurationIndex =
        device.controlConfigurations.indexOf(controlConfiguration);

      const resultDoc = await showBindingsEditor({
        bindingsDoc: controlConfiguration.bindingsBlocklyDocument,
        controlTypeTag: controlConfiguration.controlType,
        controlName: controlConfiguration.name,
        variablesList: device.variables,
      });

      if (resultDoc) {
        const updatedDevice = device.clone() as HatRetroControllerConfiguration;

        updatedDevice.variables = Object.assign(
          {},
          ...(resultDoc.variables || []).map((varDesc: any) => ({
            [varDesc.id]: { name: varDesc.name },
          }))
        );

        delete resultDoc.variables;

        updatedDevice.controlConfigurations[
          controlConfigurationIndex
        ].bindingsBlocklyDocument = resultDoc;

        onDeviceUpdated(updatedDevice);
      }
    },
    [device, onDeviceUpdated, showBindingsEditor]
  );

  const handleEditConnections = useCallback(
    async (controlConfiguration: HatRetroControlConfiguration) => {
      const controlConfigurationIndex =
        device.controlConfigurations.indexOf(controlConfiguration);

      const pinVacancies = device.pinVacancies;
      Object.values(controlConfiguration.connections).forEach(
        (connectionTarget) => (pinVacancies[connectionTarget] = true)
      );

      const resultConnections = await showConnectionsEditor({
        boardTopology: RetroBoardTopology, // TODO read from device?
        controlTypeSchema:
          GetHatRetroControlConfigurationDynamicSchemaInformation(
            controlConfiguration
          ),
        controlName: controlConfiguration.name,
        existingConnections: controlConfiguration.connections,
        controlEditingSupport:
          ControlEditingSupportList[controlConfiguration.controlType],
        pinVacancies: pinVacancies,
      });

      if (resultConnections) {
        const updatedDevice = device.clone() as HatRetroControllerConfiguration;

        updatedDevice.controlConfigurations[
          controlConfigurationIndex
        ].connections = resultConnections.updatedConnections;

        onDeviceUpdated(updatedDevice);
      }
    },
    [device, onDeviceUpdated, showConnectionsEditor]
  );

  const handleEditProperties = useCallback(
    async (controlConfiguration: HatRetroControlConfiguration) => {
      const controlConfigurationIndex =
        device.controlConfigurations.indexOf(controlConfiguration);

      const resultControlConfiguration = await showPropertiesEditor({
        controlName: controlConfiguration.name,
        controlTypeSchema:
          GetHatRetroControlConfigurationDynamicSchemaInformation(
            controlConfiguration
          ),
        editingSupport:
          ControlEditingSupportList[controlConfiguration.controlType],
        contolConfiguration: controlConfiguration,
      });

      if (resultControlConfiguration) {
        const updatedDevice = device.clone() as HatRetroControllerConfiguration;

        updatedDevice.controlConfigurations[controlConfigurationIndex] =
          resultControlConfiguration.controlConfiguration;

        onDeviceUpdated(updatedDevice);
      }
    },
    [device, onDeviceUpdated, showPropertiesEditor]
  );

  return (
    <>
      <div className="bigListTitleBar">
        <MenuButton label="Add">
          {Object.entries(ControlEditingSupportList)
            .filter(
              ([_, controlEditingSupport]) =>
                controlEditingSupport.categories.indexOf(controlCategory) >= 0
            )
            .map(([typeTag, controlEditingSupport], index) => (
              <MenuItem
                key={index}
                onClick={() => addNewControl(typeTag as HatRetroControlTypeTag)}
              >
                {controlEditingSupport.displayName}
              </MenuItem>
            ))}
        </MenuButton>
      </div>

      <div className="bigList">
        {device.controlConfigurations.map((controlConfiguration, index) => {
          const editingInfo =
            ControlEditingSupportList[controlConfiguration.controlType];

          if (editingInfo.categories.indexOf(controlCategory) < 0) {
            return <></>;
          }

          const controlConfigurationSchema =
            GetHatRetroControlConfigurationDynamicSchemaInformation(
              controlConfiguration
            );

          const EditingInfoIcon = editingInfo.icon;

          const bindingCount = Object.keys(
            controlConfigurationSchema.bindings
          ).length;

          let bindingError = "";
          let unboundBindingsCount = 0;
          try {
            const boundCount = Object.keys(
              GetHatRetroControlConfigurationBindingsBlockly(
                controlConfiguration
              )
            ).length;
            unboundBindingsCount = bindingCount - boundCount;
          } catch (e: any) {
            bindingError = e.toString();
          }

          const connectionCount =
            Object.keys(controlConfigurationSchema.connections).length +
            Object.values(controlConfigurationSchema.connectionVectors || {})
              .map((_) => _.numElements)
              .reduce((a, b) => a + b, 0);

          const connectedConnectionsCount = Object.values(
            controlConfiguration.connections
          )
            .filter((_) => _ !== undefined)
            .map((target) =>
              Array.isArray(target)
                ? target.filter((_) => _ !== undefined).length
                : 1
            )
            .reduce((a, b) => a + b, 0);

          const unconnectedConnectionsCount =
            connectionCount - connectedConnectionsCount;

          return (
            <div className="listRow" key={index}>
              <EditingIconContainer>
                <EditingInfoIcon />
                <ControlTypeLabel>{editingInfo.displayName}</ControlTypeLabel>
              </EditingIconContainer>

              <div className="titleAndDescContainer">
                <InlineEditableLabel
                  className="title"
                  value={controlConfiguration.name}
                  handleSave={(value) =>
                    updateControlConfigurationName(index, value)
                  }
                />

                <HorizontalStack>
                  <div className="descAndActions">
                    <div className="desc">
                      <span>{bindingCount} bindings</span> &nbsp;
                      {Boolean(bindingError) && <span>(Binding errors)</span>}
                      {Boolean(!bindingError && unboundBindingsCount) && (
                        <span>({unboundBindingsCount} unbound)</span>
                      )}
                    </div>
                    <div
                      className="editButton"
                      style={{ display: "inline" }}
                      onClick={() => handleEditBindings(controlConfiguration)}
                    >
                      <EditIcon
                        fontSize="small"
                        style={{ color: "rgb(192,192,192)" }}
                      />
                    </div>
                  </div>
                  {Boolean(connectionCount) && (
                    <>
                      <HorizontalStackEditItemSeparator />
                      <div className="descAndActions">
                        <div className="desc">
                          <span>{connectionCount} connections</span> &nbsp;
                          {Boolean(unconnectedConnectionsCount) && (
                            <span>
                              ({unconnectedConnectionsCount} unconnected)
                            </span>
                          )}
                        </div>
                        <div
                          className="editButton"
                          style={{ display: "inline" }}
                          onClick={() =>
                            handleEditConnections(controlConfiguration)
                          }
                        >
                          <EditIcon
                            fontSize="small"
                            style={{ color: "rgb(192,192,192)" }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {editingInfo.propertiesEditor && (
                    <>
                      <HorizontalStackEditItemSeparator />
                      <div className="descAndActions">
                        <div className="desc">Settings</div>
                        <div
                          className="editButton"
                          style={{ display: "inline" }}
                          onClick={() =>
                            handleEditProperties(controlConfiguration)
                          }
                        >
                          <EditIcon
                            fontSize="small"
                            style={{ color: "rgb(192,192,192)" }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </HorizontalStack>
              </div>

              <div className="actions">
                <DeleteIcon
                  onClick={() => deleteControl(index)}
                  style={{ color: "rgb(192,192,192)" }}
                ></DeleteIcon>
              </div>
            </div>
          );
        })}
      </div>

      {bindingsEditorDialog}
      {connectionsEditorDialog}
      {propertiesEditorDialog}
    </>
  );
}

type BindingEditorDialogInput = {
  bindingsDoc: any;
  controlTypeTag: HatRetroControlTypeTag;
  controlName: string;
  variablesList: HatRetroControllerConfiguration["variables"];
};

type BindingEditorDialogOutput = any;

function BindingEditorDialog(
  props: DialogStandardInputs<
    BindingEditorDialogInput,
    BindingEditorDialogOutput
  >
) {
  const [editedBindingsDoc, setEditedBindingsDoc] = useState(props.bindingsDoc);

  return (
    <Dialog open={true} disableEnforceFocus={true} fullWidth maxWidth="xl">
      <DialogTitle>Bindings: {props.controlName}</DialogTitle>
      <DialogContent>
        <StyledControlBindingsEditor
          bindingsBlocklyJson={editedBindingsDoc}
          controlBindingTypeTag={props.controlTypeTag}
          variablesList={props.variablesList}
          onBindindingsBlocklyJsonChanged={(newDoc) =>
            setEditedBindingsDoc(newDoc)
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(editedBindingsDoc)}>Save</Button>
        <Button onClick={() => props.onClose()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

const StyledControlBindingsEditor = styled(ControlBindingsEditor)`
  height: 70vh;
  width: 100%;
`;

type ConnectionEditorDialogInput = {
  boardTopology: BoardTopology;
  controlTypeSchema: HatRetroControlConfigurationSchemaInformationType;
  controlEditingSupport: ControlEditingSupport;
  controlName: string;
  pinVacancies: boolean[];
  existingConnections: ConnectionToPin;
};

type ConnectionEditorDialogOutput = {
  updatedConnections: ConnectionToPin;
};

function ConnectionEditorDialog(
  props: DialogStandardInputs<
    ConnectionEditorDialogInput,
    ConnectionEditorDialogOutput
  >
) {
  const [connections, setConnections] = useState<ConnectionToPin>(
    props.existingConnections
  );

  return (
    <Dialog open={true} fullWidth maxWidth="xl">
      <DialogTitle>Connections: {props.controlName}</DialogTitle>
      <VerticalStackDialogContent>
        <StyledControlConnectionsEditor
          boardTopology={props.boardTopology}
          pinVacancies={props.pinVacancies}
          connectionSchema={props.controlTypeSchema}
          controlEditingSupport={props.controlEditingSupport}
          connections={connections}
          onChangeConnections={setConnections}
        />
      </VerticalStackDialogContent>
      <DialogActions>
        <Button
          onClick={() => props.onClose({ updatedConnections: connections })}
        >
          Save
        </Button>
        <Button onClick={() => props.onClose()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

type PropertiesEditorDialogInput = {
  controlTypeSchema: HatRetroControlConfigurationSchemaInformationType;
  controlName: string;
  editingSupport: ControlEditingSupport;
  contolConfiguration: HatRetroControlConfiguration;
};

type PropertiesEditorDialogOutput = {
  controlConfiguration: HatRetroControlConfiguration;
};

function PropertiesEditorDialog(
  props: DialogStandardInputs<
    PropertiesEditorDialogInput,
    PropertiesEditorDialogOutput
  >
) {
  const [updatedControl, setUpdatedControl] = useState(
    props.contolConfiguration
  );

  const SettingsDialogPane = props.editingSupport.propertiesEditor!;

  return (
    <Dialog open={true}>
      <DialogTitle>Settings: {props.controlName}</DialogTitle>
      <VerticalStackDialogContent>
        <SettingsDialogPane
          controlConfiguration={updatedControl}
          onControlConfigurationChanged={setUpdatedControl}
        />
      </VerticalStackDialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            props.onClose({ controlConfiguration: updatedControl })
          }
        >
          Save
        </Button>
        <Button onClick={() => props.onClose()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

const StyledControlConnectionsEditor = styled(ControlConnectionsEditor)``;

const EditingIconContainer = styled.div`
  width: 160px;
  padding: 4px;
  flex-shrink: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;

  margin: 12px;
  border-radius: 6px;
`;

const VerticalStackDialogContent = styled(DialogContent)`
  display: flex;
`;

const ControlTypeLabel = styled.div`
  margin-top: 0.5rem;
  color: gray;
  font-size: 14px;
  text-align: center;
`;

const HorizontalStackEditItemSeparator = styled(HorizontalStackItemSeparator)`
  margin-left: 0;
`;
