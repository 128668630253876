import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import "./EditButton.css";
import "./InlineEditableLabel.css";

interface InlineEditableLabelProps {
  value: string;
  className?: string;
  handleSave?: (newValue: string) => void;
}

interface InlineEditableLabelState {
  editedValue: string;
  editing: boolean;
}

export class InlineEditableLabel extends React.Component<
  InlineEditableLabelProps,
  InlineEditableLabelState
> {
  constructor(props: InlineEditableLabelProps) {
    super(props);
    this.state = {
      editing: false,
      editedValue: props.value,
    };
  }

  private inputRef = React.createRef<HTMLInputElement>();

  endEditing() {
    if (this.props.handleSave) {
      this.props.handleSave(this.state.editedValue);
    }
    this.setState({ editing: false });
  }

  render(): React.ReactNode {
    if (this.state.editing) {
      return (
        <div className="inlineEditableLabel">
          <input
            className={this.props.className}
            value={this.state.editedValue}
            ref={this.inputRef}
            onChange={(event) => {
              this.setState({ editedValue: event?.target.value });
            }}
            onBlur={() => {
              this.endEditing();
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                this.endEditing();
              }
            }}
          ></input>
        </div>
      );
    } else {
      return (
        <div className="inlineEditableLabel">
          <div
            className={this.props.className + " inlineEditableLabelNonEdited"}
          >
            {this.props.value}
          </div>
          <div
            className="editButton"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              this.setState({
                editedValue: this.props.value,
                editing: true,
              });
            }}
          >
            <EditIcon style={{ color: "rgb(192,192,192)" }}></EditIcon>
          </div>
        </div>
      );
    }
  }

  componentDidUpdate() {
    if (this.inputRef.current && this.state.editing) {
      this.inputRef.current.focus();
    }
  }
}
