import { Button, Menu } from "@mui/material";
import { Fragment, ReactNode, useState } from "react"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from "react";

export interface MenuButtonProperties {
    children: ReactNode[];
    label: ReactNode;
}

export function MenuButton(props: MenuButtonProperties) {
    const [open, setOpen] = useState(false);
    const buttonRef = React.createRef<HTMLButtonElement>()
    const handleClick = () => {
        setOpen(true);
    }

    const closeMenu = () => {
        setOpen(false);
    }

    return (
        <Fragment>
            <Button
                ref = {buttonRef}
                id = "demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {props.label}
            </Button>

            <Menu
                anchorEl={() => buttonRef.current!}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClick={closeMenu}
                onClose={closeMenu}>
                {props.children}
            </Menu>
        </Fragment>
    );
}