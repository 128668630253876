import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter
} from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
  <Auth0Provider
    domain="dev--kxt9d2x.eu.auth0.com"
    clientId="drnCvkGd58KOq0ePeqUZ56nc2fpQdHcm"
    audience='https://hat-console.benhaim.net'
    useRefreshTokens={true}
    redirectUri={`${window.location.origin}/frontend/welcome`}    
    cacheLocation="localstorage" 
    >
    <React.StrictMode>
      <BrowserRouter basename='/frontend'>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
