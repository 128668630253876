import { useAuth0 } from "@auth0/auth0-react";
import { FormPageBody } from "../common/FormPageBody";

export function NewUserPage() {
  const auth0 = useAuth0();

  return <FormPageBody>
    <h1>Welcome to the HAT Console!</h1>      
    <p>Unfortunately, at this time we are only accepting new users by invitation. Thanks for understanding.</p>
    <p>
      If you were invited using a different account, you can <a href="#logout" onClick={() => auth0.logout()}>click here</a> to log out and login with that account.
    </p>
  </FormPageBody>;

} 

