import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { HatMenuCommand } from "hat-common/lib/HatMenuCommand";
import React from "react";
import { AppSystemContext } from "../../../../utilities/app-system-context";
import { AssociatedCommandEditingSupport, CommandFactory, getCommandFactories } from "./CommandEditingSupport";
import './CommandEditor.css';

import "./CommandEditorDeviceHvacControl"
import "./CommandEditorPushButton"
import "./CommandEditorUrl"

interface CommandEditorProps {
    initialCommand: HatMenuCommand;
    onUpdate?: (updatedCommand: HatMenuCommand) => void;
}

interface CommandEditorState {
    command: HatMenuCommand;
    initialCommand: HatMenuCommand;
}

export class CommandEditor extends React.Component<CommandEditorProps, CommandEditorState> {
    static contextType = AppSystemContext;
    context!: React.ContextType<typeof AppSystemContext>;
    
    constructor(props: CommandEditorProps) {
        super(props);
        this.state = {
            command: props.initialCommand.clone(),
            initialCommand: props.initialCommand
        }
    }

    componentDidUpdate() {
        if(this.state.initialCommand !== this.props.initialCommand) {
            this.setState({
                command: this.props.initialCommand.clone(),
                initialCommand: this.props.initialCommand
            });
        }
    }   

    updateCommand(cmd: HatMenuCommand) {
        this.setState({command: cmd});      
        if(this.props.onUpdate) {
            this.props.onUpdate(cmd);
        }  
    }
    
    get command(): HatMenuCommand {
        return this.state.command;
    }

    get selectedFactory(): CommandFactory {
        const factories = getCommandFactories();
        return factories.find((f) => this.state.command instanceof f.commandKlass) 
                || factories[0];
    }

    set selectedFactory(factory: CommandFactory) {
        this.updateCommand(factory.createCommand());
    }

    render(): React.ReactNode {
        const commandFactories = getCommandFactories();

        const commandFactoryMenuOptions = commandFactories.map((factory, index) => (
            <MenuItem value={index}>{factory.title}</MenuItem>
        ));

        const es = AssociatedCommandEditingSupport.createFor(
            this.state.command, 
            this.context.systemId);
        const commandEditor = 
            es.renderEditor(this.state.command, (cmd) => this.updateCommand(cmd));

        return (
            <div className="commandEditor">
                <div className="commandEditorTypeSelector">
                    <FormControl fullWidth>
                        <InputLabel id="command-type-selector-label">Command Type</InputLabel>
                        <Select
                            labelId="command-type-selector-label"
                            id="demo-simple-select"
                            value={commandFactories.indexOf(this.selectedFactory)}
                            label="Command Type"
                            onChange={(evt) => {
                                this.selectedFactory = commandFactories[evt.target.value as number]
                            }}>
                            {commandFactoryMenuOptions}
                        </Select>
                    </FormControl>
                </div>

                <div className="commandEditorTypeSpecific">
                    {commandEditor}
                </div>
            </div>
        )
    }
}
