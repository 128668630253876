import { styled, Switch } from "@mui/material";
import { useCallback } from "react";
import {
  FieldAndLabelRow,
  IndentedContainer,
  SpacedVerticalStack,
} from "../../../common/CommonStyled";

export type ValueEditorProps<TValue, TEditorAdditionalProps = {}> = {
  editedValue: TValue;
  onEditedValueChanged: (value: TValue) => void;
} & TEditorAdditionalProps;

export type OptionalValueEditorProps<TValue, TEditorAdditionalProps = {}> = {
  title: string;
  editedValue: TValue | null;
  onEditedValueChanged: (value: TValue | null) => void;
  valueEditor: (
    valueEditorProps: ValueEditorProps<TValue, TEditorAdditionalProps>
  ) => JSX.Element;
  defaultValueFactory: () => TValue;
} & TEditorAdditionalProps;

export function OptionalValueEditor<TValue, TEditorAdditionalProps = {}>({
  title,
  editedValue,
  onEditedValueChanged,
  valueEditor: ValueEditor,
  defaultValueFactory,
  ...additionalProps
}: OptionalValueEditorProps<TValue, TEditorAdditionalProps>) {
  const handleSwitchChanged = useCallback(
    (value) => {
      onEditedValueChanged(value ? defaultValueFactory() : null);
    },
    [defaultValueFactory, onEditedValueChanged]
  );

  const hasValue = editedValue !== null && editedValue !== undefined;

  return (
    <SpacedVerticalStack>
      <FieldAndLabelRow>
        <Switch
          checked={hasValue}
          onChange={(event) => handleSwitchChanged(event.target.checked)}
        />{" "}
        {title}
      </FieldAndLabelRow>
      <IndentedContainer>
        {hasValue && (
          <ValueEditor
            editedValue={editedValue}
            onEditedValueChanged={onEditedValueChanged}
            {...(additionalProps as TEditorAdditionalProps)}
          />
        )}
      </IndentedContainer>
    </SpacedVerticalStack>
  );
}
