import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DeviceDescriptorPushButton } from 'hat-common';
import { HatMenuCommand, PushButtonMenuCommand } from "hat-common/lib/HatMenuCommand";
import { ReactNode, useState } from "react";
import { CommandDetailsEditorProps, useCommandEditorHelpers } from "./CommandDetailsEditorHelper";
import './CommandEditorPushButton.css';
import { DeviceSelector } from "../DeviceSelector";
import { AssociatedCommandEditingSupport, CommandEditingSupport } from "./CommandEditingSupport";
import { humanizeDeviceName } from "../../../../utilities/device-descriptors-cache";


export function CommandEditorPushButton(props: CommandDetailsEditorProps<PushButtonMenuCommand>) {
    const [command, updateCommand] = useCommandEditorHelpers(props);
    const [selectedDeviceDescriptor, setSelectedDeviceDescriptor] = useState<DeviceDescriptorPushButton>();

    const pushButtonIds = selectedDeviceDescriptor?.pushCommands || [];

    return (
        <div className="pushButtonCommandParams">
            <DeviceSelector 
                systemId={props.systemId}
                selectedDevice={command.deviceName}
                onUpdate={(deviceName) => updateCommand((cmd) => cmd.deviceName = deviceName)}
                onSelectedDeviceResolved={(device) => setSelectedDeviceDescriptor(device as DeviceDescriptorPushButton)}
                deviceType="pushButton" />
                
            <FormControl className="buttonNameField">
                <InputLabel id="button-name-select-input-label">Button</InputLabel>
                <Select
                    value={command.pushButtonId}
                    label="Button"
                    labelId="button-name-select-input-label"
                    onChange={(evt) => {
                        updateCommand((command) => {
                            command.pushButtonId = evt.target.value as string;
                        });
                    }}>
                    
                    {pushButtonIds.map((pbid) => (
                        <MenuItem value={pbid}>{pbid}</MenuItem>
                    ))}
                </Select>
            </FormControl>

        </div>
    )
}



@AssociatedCommandEditingSupport.For(PushButtonMenuCommand)
export class CommandEditingSupportPushButton implements CommandEditingSupport {
    constructor(private systemId: string) {

    }

    title: string = "Push Button";

    createCommand(): HatMenuCommand {
        return new PushButtonMenuCommand('', '');
    }

    renderEditor(command: HatMenuCommand, onUpdate: (updatedCommand: HatMenuCommand) => void): ReactNode {
        return (
            <CommandEditorPushButton 
                systemId={this.systemId}
                command={command as PushButtonMenuCommand}
                onUpdate={onUpdate}></CommandEditorPushButton>);
    }

    describe(command: HatMenuCommand): string {
        const pbCommand = (command as PushButtonMenuCommand);
        const humanizedDeviceName = 
            humanizeDeviceName(this.systemId, pbCommand.deviceName);
        return `Activate '${pbCommand.pushButtonId}' on device '${humanizedDeviceName}'`;
    }
}