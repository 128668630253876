import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DeviceDescriptor } from "hat-common";
import { useContext, useEffect, useState } from "react";
import { AppSystemContext } from "../../../utilities/app-system-context";
import { getAvailableDeviceDescriptors } from "../../../utilities/device-descriptors-cache";

interface DeviceSelectorProps {
  systemId: string;
  selectedDevice: string;
  onUpdate?: (newValue: string) => void;
  onSelectedDeviceResolved?: (descriptor: DeviceDescriptor) => void;
  deviceType: string;
}

export function DeviceSelector(props: DeviceSelectorProps) {
  const [deviceDescriptors, setDeviceDescriptors] = useState<
    DeviceDescriptor[]
  >([]);
  const [selectedDeviceName, setSelectedDeviceName] = useState<string>(
    props.selectedDevice
  );
  const systemInfo = useContext(AppSystemContext);

  useEffect(() => {
    (async () => {
      const loadedDeviceDescriptors: DeviceDescriptor[] = (
        await getAvailableDeviceDescriptors(systemInfo.systemId)
      ).filter((d: DeviceDescriptor) => d.deviceType === props.deviceType);

      loadedDeviceDescriptors.sort((a, b) =>
        a.displayName.localeCompare(b.displayName)
      );

      setDeviceDescriptors(loadedDeviceDescriptors);

      const resolvedDescriptor = loadedDeviceDescriptors.find(
        (d) => d.fullName === selectedDeviceName
      );

      if (resolvedDescriptor && props.onSelectedDeviceResolved) {
        props.onSelectedDeviceResolved(resolvedDescriptor);
      }
    })();
  }, []);

  return (
    <FormControl className="deviceField">
      <InputLabel id="device-select-input-label">Device</InputLabel>
      <Select
        value={selectedDeviceName}
        label="Device"
        labelId="device-select-input-label"
        onChange={(evt) => {
          const devName = evt.target.value as string;
          setSelectedDeviceName(devName);
          if (props.onUpdate) {
            props.onUpdate(devName);
          }

          if (props.onSelectedDeviceResolved) {
            const resolvedDescriptor = deviceDescriptors.find(
              (d) => d.fullName === devName
            );

            if (resolvedDescriptor) {
              props.onSelectedDeviceResolved(resolvedDescriptor);
            }
          }
        }}
      >
        {deviceDescriptors.map((dd) => (
          <MenuItem value={dd.fullName}>{dd.displayName}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
