import { HatUserProfile } from "hat-common";
import { createContext } from "react";

export class UserContextInfo {
    constructor(public profileInfo: HatUserProfile) {}

    
}


export const AppUserContext = createContext<UserContextInfo | null>(null);