import { CompanionClassAssociation, DeviceConnectionConfig } from "hat-common";
import { ReactNode } from "react";

export interface ConnectionEditorProperties {
  value: DeviceConnectionConfig;
  status: any;
  setValue: (config: DeviceConnectionConfig) => void;
}

export interface ConnectionSetupProperties {
  onSetCreationCompletionCall: ((creationCompletionCall: (() => Promise<string>) | null) => void);  
}

export abstract class ConnectedDeviceEditingSupport {
  abstract createEditor(props: ConnectionEditorProperties): ReactNode;

  abstract createSetupUi(props: ConnectionSetupProperties): ReactNode;

  abstract get displayName(): string;
  abstract get iconName(): string;
}

export const AssociatedConnectedDeviceEditingSupport 
  = new CompanionClassAssociation<DeviceConnectionConfig, ConnectedDeviceEditingSupport, [systemId: string]>();
