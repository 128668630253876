import { TextField } from "@mui/material";
import { HatRetroControlConfigurationWithTypeTag } from "hat-common";
import { useCallback } from "react";
import styled from "styled-components";
import {
  FieldAndLabelRow,
  SpacedVerticalStack,
} from "../../../common/CommonStyled";
import "../../../common/EditButton.css";
import { ControlPropertiesEditorProps } from "./ControlEditingSupport";

export function ControlSettingsEditorTimer({
  controlConfiguration,
  onControlConfigurationChanged,
}: ControlPropertiesEditorProps<"tm">) {
  const updateDeviceSettings = useCallback(
    (
      settings: Partial<
        HatRetroControlConfigurationWithTypeTag<"tm">["otherSettings"]
      >
    ) => {
      const newControl: HatRetroControlConfigurationWithTypeTag<"tm"> = {
        ...controlConfiguration,
        otherSettings: {
          ...controlConfiguration.otherSettings,
          ...settings,
        },
      };

      onControlConfigurationChanged(newControl);
    },
    [controlConfiguration, onControlConfigurationChanged]
  );

  const currentSettings = controlConfiguration.otherSettings;

  return (
    <SpacedVerticalStack>
      <FieldAndLabelRow>
        <FixedWidthLabel>Timer Period:</FixedWidthLabel>
        <TextField
          type="number"
          size="small"
          inputProps={{ min: 0, max: 5 }}
          value={currentSettings.period}
          onChange={(event) =>
            updateDeviceSettings({ period: parseInt(event.target.value) })
          }
        />
        <span>ms</span>
      </FieldAndLabelRow>
    </SpacedVerticalStack>
  );
}

const FixedWidthLabel = styled.div`
  width: 14rem;
`;
