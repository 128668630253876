import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppUserContext } from "../../utilities/user-context";
import { EmptyStateContent } from "../common/EmptyStateContent";

export type WelcomePageProperties = {
  onAddSystem(): void;
}

export function WelcomePage(props: WelcomePageProperties) {
  
  const userContext = useContext(AppUserContext);

  const selectedSystem = userContext?.profileInfo.availableSystems.length ? userContext?.profileInfo.availableSystems[0] : null;

  if(selectedSystem) {
    return <Navigate to={`/systems/${selectedSystem.systemId}`}/>;
  } else {
    return (
    <EmptyStateContent 
      title={`Hi, ${userContext?.profileInfo.userDisplayName}.`} 
      buttonTitle="Create System"
      onClick={() => props.onAddSystem()}>

      <p>Welcome to the HAT Console.</p>
      <p>
        Configuring your HAT devices is easy! We'll start with creating a HAT System. Then,
        you'll be able to add your HAT devices one by one to it, as well as any home automation 
        system that you'd like your HAT devices to communicate with. And finally, you'll 
        setup the actions you would like to make available in each HAT device.
      </p>

      <p>
        Click on the button bellow to create your first HAT system.  
      </p>
    </EmptyStateContent>);    
  }  
}