import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import styled from 'styled-components'

export function LoginPage() {
  const auth0 = useAuth0();

  return <LoginContainer>
    <LoginMessage>
      You are currently not logged in. Please click the button below to login to the console.
    </LoginMessage>

    <Button variant='contained' onClick={() => auth0.loginWithRedirect()}>
      Login
    </Button>
  </LoginContainer>;
} 

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
`

const LoginMessage = styled.div`
  margin-top: 128px;
  margin-bottom: 128px;
`

