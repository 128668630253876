import { Divider, Menu, MenuItem } from "@mui/material";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import '../../App.css';
import { AppSystemContext, AppSystemContextInfo } from '../../utilities/app-system-context';
import { AppUserContext } from "../../utilities/user-context";
import { DeviceListEditor } from './devices/DeviceListEditor';
import { HatDeviceEditor } from './devices/HatDeviceEditor';
import { ConnectedDeviceEditor } from "./integrations/ConnectedDeviceEditor";
import { IntegrationListEditor } from "./integrations/IntegrationListEditor";

export type SystemEditorProps = {
  onAddSystem() : void;
}

export function SystemEditor(props: SystemEditorProps) {
  const navigate = useNavigate();
   
  const { systemId } = useParams();
  
  const userContext = useContext(AppUserContext)  
  const systemLabelRef = useRef<HTMLDivElement>(null);
  
  const [showSystemSelector, setShowSystemSelector] = useState(false);
  
  const switchSystem = useCallback((systemId: string) => {
    setShowSystemSelector(false);
    navigate(`/systems/${systemId}`);
  }, [navigate]);

  const currentSystemDisplayName = useMemo(() => 
    userContext?.profileInfo.availableSystems.find((s) => s.systemId === systemId)?.systemDisplayName
  , [systemId, userContext?.profileInfo.availableSystems]);
  
  return (
    <AppSystemContext.Provider value={new AppSystemContextInfo(systemId || "")} >
      <div className="ApplicationSubHeader">
        <div ref={systemLabelRef} className="SystemSelector" onClick={() => setShowSystemSelector(true)}>
          {currentSystemDisplayName} &#x25BC;
        </div>

        <Menu        
          anchorEl={systemLabelRef.current}
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          open={showSystemSelector}
          onClose={() => setShowSystemSelector(false)}
        >
          {userContext?.profileInfo.availableSystems.map((availableSystem) => 
            <MenuItem onClick={() => switchSystem(availableSystem.systemId)} disableRipple>
              {availableSystem.systemDisplayName}
            </MenuItem>                  
          )}

          {userContext?.profileInfo.availableSystems.length &&  <Divider/>}

          <MenuItem 
            onClick={() => {setShowSystemSelector(false); props.onAddSystem()}} 
            disableRipple>
              New System...
            </MenuItem>                  
        </Menu>

        <div className="AppNavigation">
          <NavLink to="devices" className="NavItem">Devices</NavLink>
          <NavLink to="integrations" className="NavItem">Integrations</NavLink>          
        </div>

      </div>

      <div className="AppMain">
        <Routes>          
          <Route path="devices" element={<HatDeviceListPage />}/> 
          <Route path="devices/:deviceId" element={<HatDevicePage />}/>
          <Route path="integrations" element={<IntegrationListEditor />}/>
          <Route path="integrations/connected-device/:connectionId" element={<HatConnectedDevicePage />}/>

          <Route path="" element={<Navigate to="devices"/>}/> 
        </Routes>
      </div>
    </AppSystemContext.Provider>);
}

export function HatSystemDefault() {
  return <div>System default</div>;
}

export function HatDeviceListPage() {
  return <DeviceListEditor></DeviceListEditor>
}

export function HatDevicePage() {
  const { deviceId } = useParams();  
  return <HatDeviceEditor             
            editedDeviceId={deviceId!}></HatDeviceEditor>;
}

export function HatConnectedDevicePage() {
  const { connectionId } = useParams();
  return <ConnectedDeviceEditor
            editedDeviceId={connectionId!}/>
}