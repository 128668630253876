import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from "@mui/material";
import React, { useRef } from "react";
import { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { AppSystemContext } from "../../../utilities/app-system-context";
import { AssociatedConnectedDeviceEditingSupport, ConnectedDeviceEditingSupport } from "./integration-editors/ConnectedDeviceEditingSupport";

export function AddConnectionDialog({ onClose } : { onClose: (addedId: string | null) => void }) {
  const systemContext = useContext(AppSystemContext);

  const editingSupportList = useMemo(() => {
    let ret: ConnectedDeviceEditingSupport[] = [];
    for(let connectionType of AssociatedConnectedDeviceEditingSupport.getRegisteredSources()) {
      const editingSupport = AssociatedConnectedDeviceEditingSupport.createForClass(connectionType, systemContext.systemId);
      ret.push(editingSupport);  
    }
    
    return ret;
  }, [systemContext.systemId])
 
  let completionCallback = useRef<(() => Promise<string>) | null>();

  const [completionAllowed, setCompletionAllowed] = useState(false);
  
  const [editingSupport, setEditingSupport] = useState<ConnectedDeviceEditingSupport | null>(null);

  const selectConnectionType = useCallback((connectionTypeIndex) => {
    completionCallback.current = null;
    setCompletionAllowed(false);
    setEditingSupport(editingSupportList[connectionTypeIndex]);
  }, [editingSupportList]);

  const performSave = useCallback(async () => {
    const newId = await completionCallback.current!();
    onClose(newId);
  }, [completionCallback, onClose]);

  const setupUiProps = useMemo(() => ({
    onSetCreationCompletionCall(newCallback: (() => Promise<string>) | null) {
      completionCallback.current = newCallback;
      let newCompletionAllowed = newCallback != null;
      if(newCompletionAllowed !== completionAllowed) {
        setCompletionAllowed(newCompletionAllowed);
      }
    }
  }), [completionAllowed]);

  return <>
  
    <DialogTitle>Add Connection</DialogTitle>  
    <DialogContent>
      <p>Select type of connection to add:</p> 
      <StyledSelect onChange={(event: any) => selectConnectionType(event.target.value)}>       
        {editingSupportList.map((item, index) => <MenuItem value={index}>
            {item.displayName}
        </MenuItem>)}
      </StyledSelect>

      <div>
        {editingSupport && 
          React.createElement(((props: any) => editingSupport.createSetupUi(props)) as any,
          setupUiProps)}
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(null)}>Cancel</Button>
      <Button onClick={performSave} disabled={!completionAllowed}>Create</Button>
    </DialogActions>
  </>
}

const StyledSelect = styled(Select)`
  width: 100%;
`