import { useAuth0 } from "@auth0/auth0-react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { CircularProgress, Dialog } from "@mui/material"
import { HatUserProfile, JsonDeserializationContext } from "hat-common"
import { useCallback, useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import styled from "styled-components"
import "./App.css"
import { NewSystemDialog } from "./components/config-editors/NewSystemDialog"
import { SystemEditor } from "./components/config-editors/SystemEditor"
import { LoginPage } from "./components/pages/login-page"
import { NewUserPage } from "./components/pages/new-user-page"
import { WelcomePage } from "./components/pages/welcome-page"
import { apiClient } from "./utilities/api-client"
import { AppUserContext, UserContextInfo } from "./utilities/user-context"

function App() {
	const auth0 = useAuth0()
	const navigate = useNavigate()

	const [userContext, setUserContext] = useState<UserContextInfo | null>(null)
	const [unknownUser, setUnknownUser] = useState(false)

	const [showSystemAddDialog, setShowSystemAddDialog] = useState(false)

	const exchangeProfileData = useCallback(async () => {
		try {
			const profileDataExchangeResponse = await apiClient.invokePostApi("user/exchange-profile-data", {
				email: auth0.user?.email,
				name: auth0.user?.name,
				givenName: auth0.user?.given_name,
				lastName: auth0.user?.last_name,
				picture: auth0.user?.picture,
			})

			const hatUserProfile = new HatUserProfile(new JsonDeserializationContext(profileDataExchangeResponse.data))
			setUserContext(new UserContextInfo(hatUserProfile))
		} catch (e) {
			setUnknownUser(true)
		}
	}, [auth0])

	useEffect(() => {
		if (auth0.isAuthenticated) {
			apiClient.setAccessTokenCallback(async () => {
				try {
					return await auth0.getAccessTokenSilently({
						audience: "https://hat-console.benhaim.net",
					})
				} catch (e) {
					console.error("Could not fetch refresh token silently; will try with popup", e)
					return await auth0.getAccessTokenWithPopup({
						audience: "https://hat-console.benhaim.net",
					})
				}
			})

			exchangeProfileData()
		} else {
			setUserContext(null)
		}
	}, [auth0, auth0.isAuthenticated, auth0.user?.sub, exchangeProfileData])

	const handleNewSystemDialogClose = useCallback(
		async (newSysId: string | null) => {
			setShowSystemAddDialog(false)

			await exchangeProfileData()

			if (newSysId) {
				navigate(`systems/${newSysId}`)
			}
		},
		[navigate, exchangeProfileData]
	)

	return (
		<AppUserContext.Provider value={userContext}>
			<div className="AppRoot">
				<div className="AppTitle">
					<div className="SiteName">The HAT</div>
					<div className="AccountIndicator">
						{userContext ? (
							<>
								<AccountCircleIcon></AccountCircleIcon>
								&nbsp;
								{auth0.user?.name}
								<LogoutLink href="#logout" onClick={() => auth0.logout()}>
									(Logout)
								</LogoutLink>
							</>
						) : (
							<></>
						)}
					</div>
				</div>

				{userContext ? (
					<Routes>
						<Route path="/welcome" element={<WelcomePage onAddSystem={() => setShowSystemAddDialog(true)} />} />
						<Route path="/systems/:systemId/*" element={<SystemEditor onAddSystem={() => setShowSystemAddDialog(true)} />} />
					</Routes>
				) : unknownUser ? (
					<NewUserPage />
				) : (!auth0.isLoading && !auth0.isAuthenticated) || auth0.error ? (
					<LoginPage />
				) : (
					<LoadingIndicator />
				)}
			</div>

			<Dialog maxWidth="md" fullWidth={true} open={showSystemAddDialog}>
				<NewSystemDialog onClose={handleNewSystemDialogClose} />
			</Dialog>
		</AppUserContext.Provider>
	)
}

function LoadingIndicator() {
	return (
		<LoadingIndicatorContainer>
			<SpacedCircularProgress />
			<h1>Loading...</h1>
		</LoadingIndicatorContainer>
	)
}

const SpacedCircularProgress = styled(CircularProgress)``

const LoadingIndicatorContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	margin: auto;
	margin-top: 128px;

	& ${SpacedCircularProgress} {
		margin-right: 16px;
	}

	& h1 {
		font-size: 18px;
		font-weight: normal;
	}
`

const LogoutLink = styled.a`
	color: white;
	margin-left: 16px;
	font-size: 12px;
`

export default App
