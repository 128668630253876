import { TextField } from "@mui/material";
import { HatNetLoggingConfig } from "hat-common";
import { useCallback } from "react";
import {
  FieldAndLabelRow,
  FieldLabelDiv,
  SpacedVerticalStack,
} from "../../../common/CommonStyled";
import { OptionalValueEditor, ValueEditorProps } from "./OptionalValueEditor";

export function NetworkLoggingConfigEditor({
  editedValue,
  onEditedValueChanged,
}: ValueEditorProps<HatNetLoggingConfig>) {
  const handleUrlChanged = useCallback(
    (value: string) => {
      onEditedValueChanged({ ...editedValue, networkLogUrl: value });
    },
    [editedValue, onEditedValueChanged]
  );

  const handleApiKeyChanged = useCallback(
    (value: string) => {
      onEditedValueChanged({ ...editedValue, apiKey: value });
    },
    [editedValue, onEditedValueChanged]
  );

  return (
    <SpacedVerticalStack>
      <FieldAndLabelRow>
        <FieldLabelDiv>Logging Server: </FieldLabelDiv>
        <TextField
          size="small"
          variant="filled"
          label="Server URL"
          value={editedValue.networkLogUrl}
          onChange={(event) => handleUrlChanged(event.target.value)}
        />
      </FieldAndLabelRow>

      <FieldAndLabelRow>
        <FieldLabelDiv>Authentication: </FieldLabelDiv>
        <TextField
          size="small"
          type="password"
          variant="filled"
          label="Logging API Key"
          value={editedValue.apiKey}
          autoComplete="off"
          onChange={(event) => handleApiKeyChanged(event.target.value)}
        />
      </FieldAndLabelRow>
    </SpacedVerticalStack>
  );
}

export function OptionalNetworkLoggingConfigEditor(props: {
  editedValue: HatNetLoggingConfig | null;
  onEditedValueChanged: (value: HatNetLoggingConfig | null) => void;
}) {
  return OptionalValueEditor<HatNetLoggingConfig>({
    ...props,
    title: "Use network logging",
    valueEditor: NetworkLoggingConfigEditor,
    defaultValueFactory: () => ({
      networkLogUrl:
        "https://maker.ifttt.com/trigger/Command-and-Contol-Log/with/key/cop304vj9Hmdlf547a-zbB",
      apiKey: "",
    }),
  });
}
