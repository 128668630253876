import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Toolbar, Typography } from '@mui/material';
import { HatMenuDescriptor, HatMenuItem, HatScreenImage } from 'hat-common';
import { HatMenuCommand, SubmenuMenuCommand } from 'hat-common/lib/HatMenuCommand';
import React, { ReactNode } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import '../../common/EditButton.css';
import { InlineEditableLabel } from '../../common/InlineEditableLabel';
import { CommandEditor } from './command-editing/CommandEditor';
import { ReactComponent as DragHandle } from './menu-item-drag-handle.svg';
import './MenuEditor.css';
import ScreenDisplay from './ScreenDisplay';
import ScreenEditor from './screen-editor/ScreenEditor';
import { MenuButton } from '../../common/MenuButton';
import { AssociatedCommandEditingSupport } from './command-editing/CommandEditingSupport';
import '../../common/CommonStyles.css';
import { AppSystemContext } from '../../../utilities/app-system-context';

interface MenuEditorProps {
    menu: HatMenuDescriptor;
    onMenuUpdated?: (menu: HatMenuDescriptor) => void;
}

interface MenuStackItem {
    menuName: string;
    indexInParentMenu: number;
}

interface MenuEditorState {
    originalEditedRootMenu: HatMenuDescriptor;
    
    draftRootMenu: HatMenuDescriptor;
    editedMenu: HatMenuDescriptor;
    menuStack: MenuStackItem[];

    editedItemIndex: number;
    imageEditorActive: boolean;    
    commandEditorActive: boolean;
}

export class MenuEditor extends React.Component<MenuEditorProps, MenuEditorState> {
    static contextType = AppSystemContext;
    context!: React.ContextType<typeof AppSystemContext>;

    constructor(props: MenuEditorProps) {
        super(props);
        const draftRootMenu = props.menu.clone();
        this.state = { 
            draftRootMenu: draftRootMenu,
            editedMenu: draftRootMenu,
            originalEditedRootMenu: props.menu,
            menuStack: [],
            editedItemIndex: -1,
            imageEditorActive: false,
            commandEditorActive: false
        };
    }


    componentDidUpdate() {
        if(this.state.originalEditedRootMenu !== this.props.menu) {
            const draftRootMenu = this.props.menu.clone();

            const draftEditedMenu = 
                this.state.originalEditedRootMenu.id === this.props.menu.id ? 
                    this.navigateMenuStack(draftRootMenu, this.state.menuStack) : 
                    draftRootMenu;
            
            const newMenuStack: MenuStackItem[] = 
                draftEditedMenu !== draftRootMenu ? this.state.menuStack : [];

            
            this.setState({
                draftRootMenu: draftRootMenu,
                editedMenu: draftEditedMenu,
                originalEditedRootMenu: this.props.menu,
                menuStack: newMenuStack
            });
        }
    }   

    renderMenuItem(menuItem: HatMenuItem, index: number) {
        let commandDescriptionAndEdit: ReactNode;
        if(menuItem.command instanceof SubmenuMenuCommand) {
            commandDescriptionAndEdit = (
                <div className="desc">
                    <div>{menuItem.command.description}</div>
                    &nbsp;
                    <div className="editButton"      
                            style={{display: "inline"}}
                            onClick={() => this.enterSubMenu(index)}>
                        <ArrowCircleRightIcon fontSize="small" style={{color: 'rgb(192,192,192)'}}></ArrowCircleRightIcon>
                    </div>
                </div>) ;                                
        } else {
            const es = AssociatedCommandEditingSupport.createFor(menuItem.command, this.context.systemId);            
            commandDescriptionAndEdit = (
                <div className="descAndActions">    
                    <div className="desc">{es.describe(menuItem.command)}</div>
                    
                    <div className="editButton"      
                            style={{display: "inline"}}
                            onClick={() => this.editCommand(index)}>
                        <EditIcon fontSize="small" style={{color: 'rgb(192,192,192)'}}></EditIcon>
                    </div>
                </div>);
        }

        const screenDisplay = 
            <div className="menuItemScreen">
                <ScreenDisplay image={menuItem.image}></ScreenDisplay>
                <div className="editButton"
                    style={{ position: "absolute",  right: "8px", top: "8px"}}
                    onClick={() => this.startImageEditing(index)}>
                    <EditIcon style={{color: 'rgb(192,192,192)'}}></EditIcon>
                </div>
            </div>;

        const descriptionAndCommand = 
            <div className="titleAndDescContainer">
                <InlineEditableLabel className="title" 
                                    value={menuItem.description}
                                    handleSave={(value) => {
                                        this.updateCurrentSubMenu((menu) => {
                                            menu.items[index].description = value;
                                        });
                                    }}>
                </InlineEditableLabel>
                
                {commandDescriptionAndEdit}
            </div>;

        const itemActions =                         
            <div className="actions">
                <DeleteIcon onClick={() => this.deleteItem(index)} style={{color: 'rgb(192,192,192)'}}></DeleteIcon>
            </div>;

        return (
            <Draggable
                  key={menuItem.id}
                  draggableId={menuItem.id}
                  index={index}
                >
                  {(provided) => (
                    <div ref={provided.innerRef}
                         {...provided.draggableProps}
                         className="listRow">

                        <div className="menuItemDragHandle" {...provided.dragHandleProps}>
                            <DragHandle></DragHandle>
                        </div>

                        {screenDisplay}                        

                        {descriptionAndCommand}

                        {itemActions}
                    </div>
                  )}
            </Draggable>                
        );
    }

    render() {                 
        const menuItems = 
            this.state.editedMenu.items.map((menuItem, index) => this.renderMenuItem(menuItem, index));
        
        const menuStack = this.state.menuStack;
        const breadcrumbs: ReactNode[] = [];
        const menuNameStack = this.getMenuNameStack();
        menuNameStack.slice(0, menuNameStack.length-1).forEach((breacrumbItem) => {
            breadcrumbs.push(            
                <div className="previousCrumb">
                    {breacrumbItem}
                </div>);

            breadcrumbs.push(
                <ChevronRightIcon fontSize="small"></ChevronRightIcon>);
        }); 

        breadcrumbs.push(
            <div className="currentCrumb">
                {menuNameStack[menuNameStack.length-1]}
            </div>
        );

        const titleBar =
            <div className="bigListTitleBar">
                <div className="menuEditorBreadcrumbs">
                    {breadcrumbs}                    
                </div>

                {menuStack.length > 0 && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => this.exitSubMenu()}
                        aria-label="close">
                            <ArrowUpwardIcon />
                    </IconButton>)}

                <MenuButton label="Add">
                    <MenuItem onClick={() => this.addNewItem()}>Command item</MenuItem>
                    <MenuItem onClick={() => this.addNewSubmenu()}>Submenu</MenuItem>
                </MenuButton>
            </div>;
        
        const screenEditorDialog = 
            <Dialog
                fullScreen
                scroll="paper"
                open={this.state.imageEditorActive}
                onClose={()=>this.endImageEditing()}>

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => this.endImageEditing()}
                        aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {this.state.editedMenu.items[this.state.editedItemIndex]?.description}
                        </Typography> 
                    </Toolbar>
                </AppBar>                
                <ScreenEditor 
                    image={this.state.editedMenu.items[this.state.editedItemIndex]?.image as HatScreenImage}
                    ref={this._screenEditorRef}>
                </ScreenEditor>
            </Dialog>;

        const commandEditorDialog = 
            <Dialog                    
                maxWidth="md"
                fullWidth={true}
                open={this.state.commandEditorActive}
                onClose={()=>this.endCommandEditing(false)}>
                <DialogTitle>
                    Edit Command
                </DialogTitle>
                <DialogContent>
                    {this.state.commandEditorActive && (
                        <CommandEditor 
                            ref={this._commandEditorRef} 
                            initialCommand={this.state.editedMenu.items[this.state.editedItemIndex]?.command as HatMenuCommand}>
                        </CommandEditor>)}
                </DialogContent>                    
                <DialogActions>
                    <Button onClick={() => this.endCommandEditing(true)}>Save</Button>
                    <Button onClick={() => this.endCommandEditing(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>;

        return (        
            <DragDropContext onDragEnd={(result, provided) => this.handleDragEnd(result)}>
                {titleBar}

                <Droppable droppableId="menu-editor-list">
                    {(provided) => (
                        <div ref={provided.innerRef}
                             className="bigList"
                             {...provided.droppableProps}>
                             {menuItems}
                             {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                {screenEditorDialog}

                {commandEditorDialog}  
            </DragDropContext>
        );
    }

    private _commandEditorRef = React.createRef<CommandEditor>()
    private _screenEditorRef = React.createRef<ScreenEditor>()

    private getMenuNameStack(): string[] {
        return ["Top Level Menu"].concat(this.state.menuStack.map((i) => i.menuName));
    }

    private navigateMenuStack(rootMenu: HatMenuDescriptor, stack: MenuStackItem[]) {
        let ret = rootMenu;
        stack.forEach((stackItem, index) => {
            ret = 
                (ret?.items[stackItem.indexInParentMenu]?.command as SubmenuMenuCommand)?.menu;
        });

        return ret;
    }

    private updateCurrentSubMenu(updater: (menu: HatMenuDescriptor) => void) {
        const menuStack = this.state.menuStack;
        const newRootMenu = this.state.draftRootMenu.clone();

        let menuToEdit: HatMenuDescriptor = this.navigateMenuStack(newRootMenu, menuStack);
        updater(menuToEdit);

        this.setState({
            draftRootMenu: newRootMenu,
            editedMenu: menuToEdit
        });

        if(this.props.onMenuUpdated) {
            this.props.onMenuUpdated(newRootMenu);
        }
    }

    private exitSubMenu() {
        const newStack = this.state.menuStack.slice(0, this.state.menuStack.length-1);
        const newEditedMenu = this.navigateMenuStack(this.state.draftRootMenu, newStack);
        this.setState({
            editedMenu: newEditedMenu,
            menuStack: newStack
        });        
    }

    private enterSubMenu(index: number) {
        const submenuItem = this.state.editedMenu.items[index];
        const submenu = (submenuItem.command as SubmenuMenuCommand).menu;
        const newStackEntry: MenuStackItem = {
            menuName: submenuItem.description,
            indexInParentMenu: index
        };

        this.setState({
            editedMenu: submenu,
            menuStack: this.state.menuStack.concat([newStackEntry])
        });
    }

    private addNewItem() {
        this.updateCurrentSubMenu((menu) => {
            const newItem = new HatMenuItem();
            newItem.description = "New item";
            menu.items.push(newItem);
        });
    }

    private addNewSubmenu() {
        this.updateCurrentSubMenu((menu) => {
            const newItem = new HatMenuItem();
            newItem.description = "New submenu";

            const newMenu = new HatMenuDescriptor();
            newItem.command = new SubmenuMenuCommand(newMenu);

            menu.items.push(newItem);
        });
    }

    private deleteItem(itemIndex: number) {
        this.updateCurrentSubMenu((menu) => {
            // TODO confirmation
            menu.items.splice(itemIndex, 1);            
        });        
    }

    private handleDragEnd(result: DropResult): void {
        const resultDest = result.destination;
        if(result.reason === "DROP" && resultDest) {
            this.updateCurrentSubMenu((menu) => {
                const srcIndex = result.source.index;
                const destIndex = resultDest.index;
    
                menu.items.splice(destIndex, 0, 
                        ...menu.items.splice(srcIndex, 1));
            });
        }
    }

    private endImageEditing() {
        const screenEditor = this._screenEditorRef.current;
        if(screenEditor) {
            this.updateCurrentSubMenu((menu) => {
                menu.items[this.state.editedItemIndex].image = screenEditor.editedImage;
            });
        }

        this.setState({imageEditorActive: false});
    }

    private startImageEditing(itemIndex: number) {
        this.setState({
            editedItemIndex: itemIndex,
            imageEditorActive: true
        });
    }

    private editCommand(itemIndex: number) {
        this.setState({
            editedItemIndex: itemIndex,
            commandEditorActive: true
        });
    }

    private endCommandEditing(save: boolean) {        
        const commandEditor = this._commandEditorRef.current;
        if(save && commandEditor) { 
            this.updateCurrentSubMenu((menu) => {
                menu.items[this.state.editedItemIndex].command = commandEditor.command;
            });            
        }

        this.setState({
            commandEditorActive: false
        });
    }
}
