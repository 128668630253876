import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useCallback, useState } from "react";
import { apiClient } from "../../utilities/api-client";

export type NewSystemDialogProps = {
  onClose(addedSystem: string | null): void;
};
export function NewSystemDialog(props: NewSystemDialogProps) {
  const [includeDemoDevice, setIncludeDemoDevice] = useState(true);
  const [systemName, setSystemName] = useState("");

  const completionAllowed = !!systemName;

  const performAdd = useCallback(async () => {
    try {
      const systemAddResult = await apiClient.invokePostApi(
        "system/add-system",
        {
          displayName: systemName,
          createDemoDevice: includeDemoDevice,
        }
      );

      props.onClose(systemAddResult.data.systemId);
    } catch (e) {
      alert(`Error adding system: ${e}`);
    }
  }, [systemName, includeDemoDevice, props]);

  return (
    <>
      <DialogTitle>Create a HAT System</DialogTitle>
      <DialogContent>
        <p>
          HAT devices and controlled devices are grouped together to Systems.
          You can have multiple systems in your account to represent different
          homes or isolated areas. If your HAT devices control the same set of
          integrations, that's a good sign that they should be a part of the
          same HAT System.
        </p>
        <p>Input a name for your new system (for example, an address):</p>

        <p>
          <TextField
            label="System Name"
            size="small"
            fullWidth
            variant="filled"
            value={systemName}
            onChange={(event) => setSystemName(event.target.value)}
          />
        </p>

        <p>
          You can include a demo device in your HAT system for experimenting
          with device configuration without adding a physical HAT device to the
          system. Use this if you do not have a HAT device but would still like
          to experiment with the configuration experience.
        </p>

        <FormControlLabel
          control={
            <Checkbox
              checked={includeDemoDevice}
              onChange={(event, checked) => setIncludeDemoDevice(checked)}
            />
          }
          label="Add a demo HAT device to system"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(null)}>Cancel</Button>
        <Button onClick={performAdd} disabled={!completionAllowed}>
          Add System
        </Button>
      </DialogActions>
    </>
  );
}
