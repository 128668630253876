export class ApiClient {
	setAccessTokenCallback(cb: () => Promise<string>): void {
		this._accessTokenCallback = cb
	}

	async setConnectionEnabled(systemId: string, connectionId: string, enabled: boolean) {
		const enableRequest = {
			systemId: systemId,
			connectionId: connectionId,
			enable: enabled,
		}

		await this.invokePostApi("system/set-device-connection-enabled", enableRequest)
	}

	async invokePostApi(apiEndpoint: string, body: any) {
		const tok = await this.getAccessToken()

		const requestOptions: RequestInit = {
			method: "POST",
			body: body instanceof FormData ? body : JSON.stringify(body),
			headers: {
				Authorization: `Bearer ${tok}`,
				...(body instanceof FormData ? {} : { "Content-Type": "application/json" }),
			},
		}
		const r = await fetch(`/api/v1/console/${apiEndpoint}`, requestOptions)

		if (!r.ok) {
			const rt = await r.text()
			throw new Error(`Error received from server: ${rt}`)
		}
		if (!r.ok) {
			const rt = await r.text()
			throw new Error(`Error received from server: ${rt}`)
		}
		return await r.json()
	}

	async invokeGetApi(apiEndpoint: string, qparams: any) {
		const tok = await this.getAccessToken()

		const qs = Object.keys(qparams).map((k) => {
			const v = qparams[k]
			return `${k}=${encodeURIComponent(v)}`
		})

		const r = await fetch(`/api/v1/console/${apiEndpoint}?${qs.join("&")}`, {
			headers: {
				Authorization: `Bearer ${tok}`,
			},
		})

		if (!r.ok) {
			const rt = await r.text()
			throw new Error(`Error received from server: ${rt}`)
		}

		return await r.json()
	}

	private async getAccessToken(): Promise<string> {
		if (!this._cachedAccessToken) {
			this._cachedAccessToken = await this._accessTokenCallback()
		}

		return this._cachedAccessToken!
	}

	private _accessTokenCallback: () => Promise<string> = () => Promise.reject(new Error("No token access callback"))
	private _cachedAccessToken: string | null = null
}

export const apiClient = new ApiClient()
