import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  HatMenuCommand,
  UrlHitMenuCommand,
  UrlMenuCommandHeader,
  UrlMenuCommandMethod,
} from "hat-common/lib/HatMenuCommand";
import { ReactNode } from "react";
import {
  CommandDetailsEditorProps,
  useCommandEditorHelpers,
} from "./CommandDetailsEditorHelper";
import {
  AssociatedCommandEditingSupport,
  CommandEditingSupport,
} from "./CommandEditingSupport";
import "./CommandEditorUrl.css";

export function CommandEditorUrl(
  props: CommandDetailsEditorProps<UrlHitMenuCommand>
) {
  const [command, updateCommand] = useCommandEditorHelpers(props);

  const headerRow = (header: UrlMenuCommandHeader, index: number) => {
    return (
      <div className="headerEditor">
        <input
          value={header.name}
          placeholder="Name"
          onChange={(evt) => {
            updateCommand((command) => {
              command.headers[index].name = evt.target.value;
            });
          }}
        ></input>
        :
        <input
          value={header.value}
          placeholder="Value"
          onChange={(evt) => {
            updateCommand((command) => {
              command.headers[index].value = evt.target.value;
            });
          }}
        ></input>
        <div className="deleteHeaderButton">
          <DeleteIcon
            onClick={() => {
              updateCommand((command) => {
                command.headers.splice(index, 1);
              });
            }}
            style={{ color: "rgb(192,192,192)" }}
          ></DeleteIcon>
        </div>
      </div>
    );
  };

  return (
    <div className="urlCommandParams">
      <div className="urlAndMethod">
        <FormControl className="methodField">
          <InputLabel id="method-select-input-label">Method</InputLabel>
          <Select
            value={command.method}
            label="Method"
            labelId="method-select-input-label"
            onChange={(evt) => {
              updateCommand((command) => {
                command.method = evt.target.value as UrlMenuCommandMethod;
              });
            }}
          >
            <MenuItem value="get">GET</MenuItem>
            <MenuItem value="post">POST</MenuItem>
          </Select>
        </FormControl>

        <TextField
          className="urlField"
          label="URL"
          value={command.url}
          onChange={(evt) => {
            updateCommand((command) => {
              command.url = evt.target.value;
            });
          }}
        ></TextField>
      </div>

      {command.method === "post" && (
        <TextField
          className="bodyField"
          multiline
          label="Body"
          value={command.body}
          rows={2}
          inputProps={{ className: "bodyInput" }}
          onChange={(evt) => {
            updateCommand((command) => {
              command.body = evt.target.value;
            });
          }}
        ></TextField>
      )}

      <div className="headersList">
        <div className="listTitle">
          <h1>Headers</h1>
          <AddIcon
            onClick={() => {
              updateCommand((command) => {
                command.headers.push({ name: "", value: "" });
              });
            }}
          ></AddIcon>
        </div>
        {command.headers.map((hdr, index) => headerRow(hdr, index))}
      </div>
    </div>
  );
}

@AssociatedCommandEditingSupport.For(UrlHitMenuCommand)
export class CommandEditingSupportUrl implements CommandEditingSupport {
  constructor(private systemId: string) {}

  title: string = "Invoke URL";

  createCommand(): HatMenuCommand {
    return new UrlHitMenuCommand("", "get", []);
  }

  renderEditor(
    command: HatMenuCommand,
    onUpdate: (updatedCommand: HatMenuCommand) => void
  ): ReactNode {
    return (
      <CommandEditorUrl
        systemId={this.systemId}
        command={command as UrlHitMenuCommand}
        onUpdate={onUpdate}
      ></CommandEditorUrl>
    );
  }

  describe(command: HatMenuCommand): string {
    return command.description;
  }
}
