import { HatDeviceType } from "hat-common";

export type HatDeviceTypeInfo = {
  displayName: string;
};

export const HatDeviceTypeInfos: { [k in HatDeviceType]: HatDeviceTypeInfo } = {
  "einne": { displayName: "Home Automation Terminal" },
  "retro-console": { displayName: "Retro Console" },
  "sample": { displayName: "Sample device" }
};