import { Card, CardActions } from "@mui/material";
import styled from "styled-components";

export const CardGrid = styled.div`
  padding: 48px 64px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    padding: 24px 32px;
  }
`;

export const CardListCard = styled(Card)``;

export const CardListAddCard = styled(Card)`
  display: grid;
`;

export const FlexCardActions = styled(CardActions)`
  display: flex;
  justify-content: flex-end;
`;

export const FlexCardActionsSpacer = styled.div`
  flex-grow: 1;
  min-width: 20px;
`;

export const HorizontalStack = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HorizontalStackItemSeparator = styled(({ className }) => (
  <div className={className}>&bull;</div>
))`
  margin: 0 1rem;
`;

export const FieldLabelDiv = styled.div``;

export const FieldAndLabelRow = styled(HorizontalStack)`
  align-items: baseline;
  gap: 1rem;

  ${FieldLabelDiv} {
    width: 12rem;
  }
`;

export const VerticalStack = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpacedVerticalStack = styled(VerticalStack)`
  gap: 1rem;
`;

export const IndentedContainer = styled.div`
  margin-left: 2rem;
`;
