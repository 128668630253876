import { TextField } from "@mui/material";
import { HatMqttConnectionConfig } from "hat-common";
import { usePropertiesUpdater } from "../../../../utilities/hooks";
import {
  FieldAndLabelRow,
  FieldLabelDiv,
  SpacedVerticalStack,
} from "../../../common/CommonStyled";
import { ValueEditorProps } from "./OptionalValueEditor";

export function MqttConnectionConfigEditor({
  editedValue,
  onEditedValueChanged,
}: ValueEditorProps<HatMqttConnectionConfig>) {
  const updateProperties = usePropertiesUpdater(
    editedValue,
    onEditedValueChanged
  );

  return (
    <SpacedVerticalStack>
      <FieldAndLabelRow>
        <FieldLabelDiv>MQTT Server:</FieldLabelDiv>
        <TextField
          size="small"
          variant="filled"
          label="Host"
          value={editedValue.server}
          onChange={(event) => updateProperties({ server: event.target.value })}
        />{" "}
        :
        <TextField
          size="small"
          variant="filled"
          label="Port"
          type="number"
          inputProps={{ min: 1, max: 65535 }}
          value={editedValue.port}
          onChange={(event) =>
            updateProperties({ port: parseInt(event.target.value) })
          }
        />
      </FieldAndLabelRow>
      <FieldAndLabelRow>
        <FieldLabelDiv>Authentication:</FieldLabelDiv>
        <SpacedVerticalStack>
          <TextField
            size="small"
            variant="filled"
            label="Username"
            value={editedValue.username}
            onChange={(event) =>
              updateProperties({ username: event.target.value })
            }
          />
          <TextField
            size="small"
            type="password"
            variant="filled"
            label="Password"
            autoComplete="off"
            value={editedValue.password}
            onChange={(event) =>
              updateProperties({ password: event.target.value })
            }
          />
        </SpacedVerticalStack>
      </FieldAndLabelRow>
    </SpacedVerticalStack>
  );
}
