import { Button, CardContent, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { HatDeviceInfo } from "hat-common";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeviceAddDialog } from "../../../DeviceAddDialog";
import { apiClient } from "../../../utilities/api-client";
import { AppSystemContext } from "../../../utilities/app-system-context";
import { CardGrid, CardListAddCard, CardListCard, FlexCardActions, FlexCardActionsSpacer } from '../../common/CommonStyled';
import { EmptyStateContent } from "../../common/EmptyStateContent";
import { InlineEditableLabel } from "../../common/InlineEditableLabel";
import { ScreenTitleBar } from "../../common/ScreenTitleBar";
import { SuspenseView, useSuspense } from "../../common/Suspense";
import './DeviceListEditor.css';

export interface DeviceListEditorProps {    
}

export function DeviceListEditor(props: DeviceListEditorProps) {    
    const navigate = useNavigate();

    const [deviceList, deviceListSuspense, reloadDeviceList] = useSuspense<HatDeviceInfo[]>(
        async () => {        
            const jsonResponse = await apiClient.invokeGetApi("system/hat-devices-info", {
                "s": systemContext.systemId
            });
          
            return jsonResponse.data.deviceInfo;
        },
        []
    )

    const [deviceAddActive, setDeviceAddActive] = useState<boolean>(false);
    
    const systemContext = useContext(AppSystemContext);

    const deleteDevice = async (d: HatDeviceInfo) => {
        const deleteRequest = {
            deviceId: d.deviceId,
            systemId: systemContext.systemId
        };

        try {
            await apiClient.invokePostApi('system/delete-hat-device', deleteRequest);
        } finally {
            reloadDeviceList();
        }
    }

    const editDevice = (d: HatDeviceInfo) => {
        navigate(d.deviceId);
    }

    const renameDevice = async (d: HatDeviceInfo, newName: string) => {
        const renameRequest = {
            systemId: systemContext.systemId,
            deviceId: d.deviceId,
            newName: newName
          };
      
        try {
            await apiClient.invokePostApi('system/rename-hat-device', renameRequest);
        } catch(e) {
            alert(e);          
        } finally {
            reloadDeviceList();
        }
    }
    
    const deviceCard = (d: HatDeviceInfo) => {
        return (
            <CardListCard>
                <CardContent className="listRow">
                    <div className="titleAndDescContainer">
                        <div className="title">
                            <InlineEditableLabel 
                                className="title"
                                value={d.deviceName} 
                                handleSave={(n) => renameDevice(d, n)}></InlineEditableLabel>
                        </div>
                        
                        <div className="desc">                    
                            <div>{d.deviceModel}</div>
                        </div>                                  
                    </div>                   
                </CardContent>
                <FlexCardActions>
                    <FlexCardActionsSpacer/>
                    <Button onClick={() => editDevice(d)}>Edit</Button>
                    <Button onClick={() => deleteDevice(d)}>Delete</Button>
                </FlexCardActions>
            </CardListCard>
        );
    }
    
    return (
        <div>
            <div className="deviceList">
                <ScreenTitleBar>
                    <ScreenTitleBar.TitleText>Device List</ScreenTitleBar.TitleText>
                </ScreenTitleBar>

                <SuspenseView suspenseHandle={deviceListSuspense}>
                    {deviceList?.length ?

                        <CardGrid>
                            {deviceList!.map((d) => deviceCard(d))}

                            <CardListAddCard style={{height: '140px'}}>
                                <Button 
                                    onClick={ () => setDeviceAddActive(true) } >

                                    Add Device
                                </Button>
                            </CardListAddCard>
                        </CardGrid> :

                        <EmptyStateContent 
                            title="This system has no HAT devices." 
                            buttonTitle="Add Device"
                            onClick={() => setDeviceAddActive(true)}
                            >
                            <p>You can add HAT devices to this system by clicking the button below.</p>
                        </EmptyStateContent>        
                    }
                </SuspenseView>
            </div>

            <Dialog                    
                    maxWidth="md"
                    fullWidth={true}
                    open={deviceAddActive}>
                    <DialogTitle>
                        Add New Device
                    </DialogTitle>
                    <DialogContent>
                        {deviceAddActive && (
                            <DeviceAddDialog
                                onComplete={ () => {
                                    setDeviceAddActive(false);
                                    reloadDeviceList();
                                }}>

                            </DeviceAddDialog>)}
                    </DialogContent>                    
            </Dialog>
        </div>);
}