import {
  ControlConnectionDescriptor,
  ControlConnectionType,
  HatRetroControlConfigurationSchemaInformationType,
} from "hat-common";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { HorizontalStack } from "../../../common/CommonStyled";
import { BoardTopology } from "./BoardEditingSupport";
import {
  ConnectionTypeColors,
  ControlEditingSupport,
} from "./ControlEditingSupport";

const connectionOutletsWidth = 200;
const connectionOutletsMargin = 10;

export type ConnectionToPin = Record<string, number | (number | undefined)[]>;

type ControlConnectionsEditorProps = {
  boardTopology: BoardTopology;
  pinVacancies: boolean[];
  connectionSchema: HatRetroControlConfigurationSchemaInformationType;
  controlEditingSupport: ControlEditingSupport;
  connections: ConnectionToPin;
  onChangeConnections: (newConnections: ConnectionToPin) => void;
};

export function ControlConnectionsEditor({
  boardTopology,
  pinVacancies,
  connectionSchema,
  connections,
  controlEditingSupport,
  onChangeConnections,
}: ControlConnectionsEditorProps) {
  const [svgRef, setSvgRef] = useState<SVGSVGElement>();
  const {
    boardSvgContent,
    svgViewbox,
    screenToViewTransform,
    pinToElement,
    connectionsAnchorX,
    connectionsAnchorY,
  } = useBoardTopology(boardTopology, svgRef);

  const [hoveredPin, setHoveredPin] = useState<number>();
  const [restrictedPinType, setRestrictedPinType] =
    useState<ControlConnectionType>();

  const [editedConnection, setEditedConnection] = useState<{
    connectionMoniker: ConnectionMoniker;
    connectionType: ControlConnectionType;
    startX: number;
    startY: number;
    endX: number;
    endY: number;
  }>();

  const startConnecting = useCallback(
    (
      connectionMoniker: ConnectionMoniker,
      connectionAnchorX: number,
      connectionAnchorY: number
    ) => {
      const connectionType =
        connectionMoniker.indexInVector === undefined
          ? connectionSchema.connections[connectionMoniker.name].connectionType
          : connectionSchema.connectionVectors[connectionMoniker.name]
              .connectionType;

      setEditedConnection({
        connectionMoniker,
        connectionType,
        startX: connectionAnchorX,
        startY: connectionAnchorY,
        endX: connectionAnchorX,
        endY: connectionAnchorY,
      });

      setRestrictedPinType(connectionType);

      const newConnections = { ...connections };
      setConnectionTarget(newConnections, connectionMoniker, undefined);
      onChangeConnections(newConnections);
    },
    [
      connectionSchema.connectionVectors,
      connectionSchema.connections,
      connections,
      onChangeConnections,
    ]
  );

  const handleSvgMouseMove = useCallback<MouseEventHandler>(
    (event) => {
      if (editedConnection) {
        const clrect = svgRef!.getBoundingClientRect();
        setEditedConnection((connection) => ({
          ...connection!,
          endX: event.clientX - clrect.left,
          endY: event.clientY - clrect.top,
        }));
      }
    },
    [editedConnection, svgRef]
  );

  const handleSvgMouseUp = useCallback<MouseEventHandler>(
    (event) => {
      if (editedConnection) {
        setEditedConnection(undefined);
        setRestrictedPinType(undefined);

        if (
          hoveredPin !== undefined &&
          boardTopology.pins[hoveredPin].pinTypes.includes(
            editedConnection.connectionType
          ) &&
          pinVacancies[hoveredPin]
        ) {
          const newConnections = { ...connections };

          Object.entries(newConnections).forEach(([connectionName, target]) => {
            if (target === hoveredPin) {
              delete newConnections[connectionName];
            } else if (Array.isArray(target)) {
              const indexInTarget = target.indexOf(hoveredPin);
              if (indexInTarget !== -1) {
                target[indexInTarget] = undefined;
              }
            }
          });

          setConnectionTarget(
            newConnections,
            editedConnection.connectionMoniker,
            hoveredPin
          );

          onChangeConnections(newConnections);
        }
      }
    },
    [
      boardTopology.pins,
      connections,
      editedConnection,
      hoveredPin,
      onChangeConnections,
      pinVacancies,
    ]
  );

  return (
    <svg
      viewBox={svgViewbox}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ref={(node) => {
        setSvgRef(node as SVGSVGElement);
      }}
      preserveAspectRatio="xMidYMid meet"
      onMouseMove={handleSvgMouseMove}
      onMouseUp={handleSvgMouseUp}
      style={{ userSelect: "none", width: "100%", maxHeight: "100%" }}
    >
      <defs>
        <filter
          id="washout"
          filterUnits="objectBoundingBox"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
        >
          <feFlood flood-color="#ffffff" flood-opacity="0.5" result="flood" />
          <feBlend mode="screen" in2="flood" in="SourceGraphic" />
        </filter>
      </defs>

      <g dangerouslySetInnerHTML={{ __html: boardSvgContent }}></g>

      <g transform={screenToViewTransform}>
        {svgRef &&
          pinToElement.map((pinElement, pinIndex) => (
            <PinConduit
              key={pinIndex}
              boundingBox={getNodeScreenRelativeBbox(pinElement)}
              pinTypes={boardTopology.pins[pinIndex].pinTypes.filter(
                (pinType) =>
                  restrictedPinType == null || pinType === restrictedPinType
              )}
              vacant={pinVacancies[pinIndex]}
              onMouseEnter={() => setHoveredPin(pinIndex)}
              onMouseLeave={() => setHoveredPin(undefined)}
            />
          ))}

        <ControlOutletsAndConnections
          anchorX={connectionsAnchorX}
          anchorY={connectionsAnchorY}
          controlEditingInfo={controlEditingSupport}
          connectionSchema={connectionSchema}
          connections={connections}
          pinToElement={pinToElement}
          onConnectionMouseDown={startConnecting}
        />

        {editedConnection && (
          <path
            d={`M${editedConnection.startX}, ${editedConnection.startY} L${editedConnection.endX}, ${editedConnection.endY}`}
            stroke="black"
            stroke-dasharray="5,5"
          />
        )}
      </g>
    </svg>
  );
}

type ConnectionConduitProps = {
  boundingBox: BoundingBoxData;
  pinTypes: ControlConnectionType[];
  vacant: boolean;
  onMouseEnter?: (evt: React.MouseEvent<SVGElement, MouseEvent>) => void;
  onMouseLeave?: (evt: React.MouseEvent<SVGElement, MouseEvent>) => void;
};

function PinConduit({
  boundingBox,
  pinTypes,
  vacant,
  onMouseEnter,
  onMouseLeave,
}: ConnectionConduitProps) {
  const { adjustedBoundingBox, segmentWidth, segmentHeight, conduitCols } =
    useMemo(() => {
      const adjWidth = Math.max(
        boundingBox.bottomRight.x - boundingBox.topLeft.x,
        15
      );

      const adjHeight = Math.max(
        boundingBox.bottomRight.y - boundingBox.topLeft.y,
        10
      );

      const adjustedBoundingBox = {
        topLeft: {
          x: boundingBox.mid.x - adjWidth / 2,
          y: boundingBox.mid.y - adjHeight / 2,
        },
        bottomRight: {
          x: boundingBox.mid.x + adjWidth / 2,
          y: boundingBox.mid.y + adjHeight / 2,
        },
        mid: boundingBox.mid,
      };

      const conduitRows = adjWidth / pinTypes.length < 5 ? 2 : 1;
      const conduitCols = Math.ceil(pinTypes.length / conduitRows);

      const segmentWidth = adjWidth / conduitCols;
      const segmentHeight = adjHeight / conduitRows;

      return {
        adjustedBoundingBox,
        segmentWidth,
        segmentHeight,
        conduitRows,
        conduitCols,
      };
    }, [boundingBox, pinTypes]);

  return (
    <g>
      {pinTypes.map((pinType, pinTypeIndex) => {
        const gridX = pinTypeIndex % conduitCols;
        const gridY = Math.floor(pinTypeIndex / conduitCols);

        return (
          <path
            d={`
            M${adjustedBoundingBox.topLeft.x + segmentWidth * gridX}, ${
              adjustedBoundingBox.topLeft.y + segmentHeight * gridY
            }             
            l ${segmentWidth}, 0
            l 0, ${segmentHeight}
            l -${segmentWidth}, 0
            l 0, -${segmentHeight}`}
            style={{
              fill: ConnectionTypeColors[pinType],
              filter: !vacant ? "url(#washout)" : "",
            }}
            onMouseEnter={(event) => onMouseEnter?.(event)}
            onMouseLeave={(event) => onMouseLeave?.(event)}
          />
        );
      })}
    </g>
  );
}

type ConnectionMoniker = { name: string; indexInVector?: number };

function getConnectionTarget(
  connections: ConnectionToPin,
  connectionMoniker: ConnectionMoniker
): number | undefined {
  const target = connections[connectionMoniker.name];
  if (
    connectionMoniker.indexInVector === undefined &&
    typeof target === "number"
  ) {
    return target;
  }

  if (connectionMoniker.indexInVector !== undefined && Array.isArray(target)) {
    return target[connectionMoniker.indexInVector] ?? undefined;
  }

  return undefined;
}

function setConnectionTarget(
  connections: ConnectionToPin,
  connectionMoniker: ConnectionMoniker,
  connection: number | undefined
) {
  if (connectionMoniker.indexInVector === undefined) {
    if (connection !== undefined) {
      connections[connectionMoniker.name] = connection;
    } else {
      delete connections[connectionMoniker.name];
    }
  } else {
    let targetArray = connections[connectionMoniker.name];
    if (!Array.isArray(targetArray)) {
      targetArray = [];
      connections[connectionMoniker.name] = targetArray;
    }

    targetArray[connectionMoniker.indexInVector] = connection;
  }
}

type ControlOutletsAndConnectionsProps = {
  anchorX: number;
  anchorY: number;
  controlEditingInfo: ControlEditingSupport;
  connectionSchema: HatRetroControlConfigurationSchemaInformationType;
  connections: ConnectionToPin;
  pinToElement: SVGGElement[];
  onConnectionMouseDown?: (
    connectionMoniker: ConnectionMoniker,
    conduitAnchorX: number,
    condiutAnchorY: number
  ) => void;
};
function ControlOutletsAndConnections({
  connectionSchema,
  connections,
  controlEditingInfo,
  pinToElement,
  anchorX,
  anchorY,
  onConnectionMouseDown,
}: ControlOutletsAndConnectionsProps) {
  const flattenedConnections: {
    connectionMoniker: ConnectionMoniker;
    connectionInfo: ControlConnectionDescriptor;
  }[] = useMemo(
    () => [
      ...Object.entries(connectionSchema.connections).map(
        ([connectionName, connectionInfo]) => ({
          connectionMoniker: { name: connectionName },
          connectionInfo: connectionInfo,
        })
      ),

      ...Object.entries(connectionSchema.connectionVectors).flatMap(
        ([vectorConnectionName, connectionInfo]) =>
          [...Array(connectionInfo.numElements).keys()].map(
            (connectionVectorIndex) => ({
              connectionMoniker: {
                name: vectorConnectionName,
                indexInVector: connectionVectorIndex,
              },
              connectionInfo: { connectionType: connectionInfo.connectionType },
            })
          )
      ),
    ],
    [connectionSchema]
  );

  const width = connectionOutletsWidth;

  const height = useMemo(
    () => flattenedConnections.length * 40 + 40 + 2 * 20 - 10,
    [flattenedConnections]
  );

  const { top, left } = useMemo(
    () => ({
      left: anchorX >= 0 ? anchorX : -anchorX - width,
      top: anchorY >= 0 ? anchorY : -anchorY - height,
    }),
    [anchorX, anchorY, width, height]
  );

  function getConnectionOutletCoordinate(connectionIndex: number) {
    return { x: left + 20, y: top + 60 + connectionIndex * 40 };
  }

  const ControlIcon = controlEditingInfo.icon;
  return (
    <g>
      <rect
        x={left}
        y={top}
        width={width}
        height={height}
        rx="5"
        fill="rgba(255, 255, 255, 0.9)"
        stroke="silver"
        style={{ filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))" }}
      />
      <foreignObject x={left} y={top} width={width} height="54">
        <OutletHeaderContainer>
          <ControlIconContainer>
            <ControlIcon></ControlIcon>
          </ControlIconContainer>
          <span>{controlEditingInfo.displayName}</span>
        </OutletHeaderContainer>
      </foreignObject>
      {flattenedConnections.map(
        ({ connectionMoniker, connectionInfo }, connectionIndex) => {
          const connectionTarget = getConnectionTarget(
            connections,
            connectionMoniker
          );

          const connectionConduitCoordinate =
            getConnectionOutletCoordinate(connectionIndex);

          const connectionConduitAnchor = {
            x: connectionConduitCoordinate.x + 10,
            y: connectionConduitCoordinate.y + 14,
          };

          const connectionTargetConduitBindingBox =
            connectionTarget !== undefined && pinToElement?.length
              ? getNodeScreenRelativeBbox(pinToElement[connectionTarget])
              : undefined;

          return (
            <>
              <g
                transform={`translate(${connectionConduitCoordinate.x}, ${connectionConduitCoordinate.y})`}
              >
                <circle
                  cx="10"
                  cy="14"
                  r="8"
                  fill={ConnectionTypeColors[connectionInfo.connectionType]}
                  onMouseDown={() =>
                    onConnectionMouseDown?.(
                      connectionMoniker,
                      connectionConduitAnchor.x,
                      connectionConduitAnchor.y
                    )
                  }
                />
                <text
                  x="26"
                  y="8"
                  style={{ dominantBaseline: "central", fontSize: "16px" }}
                >
                  {connectionMoniker.indexInVector === undefined
                    ? connectionMoniker.name
                    : `${connectionMoniker.name} [${connectionMoniker.indexInVector}]`}
                </text>
                <text
                  x="26"
                  y="22"
                  style={{ dominantBaseline: "central", fontSize: "10px" }}
                  fill="gray"
                >
                  {ControlConnectionType[connectionInfo.connectionType]}
                </text>
              </g>
              {connectionTargetConduitBindingBox !== undefined && (
                <path
                  d={
                    `M ${connectionConduitAnchor.x}, ${connectionConduitAnchor.y} ` +
                    `L ${connectionTargetConduitBindingBox.mid.x}, ${connectionTargetConduitBindingBox.mid.y} }`
                  }
                  stroke={ConnectionTypeColors[connectionInfo.connectionType]}
                  stroke-width="3"
                />
              )}
            </>
          );
        }
      )}
    </g>
  );
}

function useBoardTopology(
  boardTopology: BoardTopology,
  svgRef: SVGSVGElement | undefined
) {
  const [boardSvgContent, setBoardSvgContent] = useState<any>("");
  const [svgViewbox, setSvgViewbox] = useState("");
  const [screenToViewTransform, setScreenToViewTransform] = useState("");
  const [pinToElement, setPinToElement] = useState<SVGGElement[]>([]);
  const [connectionsAnchorX, setConnectionsAnchorX] = useState(0);
  const [connectionsAnchorY, setConnectionsAnchorY] = useState(0);

  useEffect(() => {
    (async () => {
      const responseText = await (
        await fetch(`/frontend/board-layouts/${boardTopology.topologyName}.svg`)
      ).text();
      const parsedSvg = new window.DOMParser().parseFromString(
        responseText,
        "text/xml"
      );

      const svgRoot = (parsedSvg as XMLDocument)
        .getRootNode()
        .childNodes.item(0) as HTMLElement;
      const serializer = new XMLSerializer();
      const nodes = Array.from(svgRoot.childNodes)
        .map((_) => serializer.serializeToString(_))
        .join("");

      setSvgViewbox(svgRoot.getAttribute("viewBox") ?? "0 0 100 100");
      setBoardSvgContent(nodes);
    })();
  }, [boardTopology.topologyName, svgRef]);

  const updateSvgLayout = useCallback(() => {
    const ctm = svgRef?.getCTM()?.inverse();
    setScreenToViewTransform(
      `matrix(${ctm?.a}, ${ctm?.b}, ${ctm?.c}, ${ctm?.d}, ${ctm?.e}, ${ctm?.f})`
    );

    if (svgRef) {
      setConnectionsAnchorX(
        boardTopology.outletsAnchor.includes("Left")
          ? connectionOutletsMargin
          : -(svgRef.clientWidth - connectionOutletsMargin)
      );
      setConnectionsAnchorY(
        boardTopology.outletsAnchor.includes("top")
          ? connectionOutletsMargin
          : -(svgRef.clientHeight - connectionOutletsMargin)
      );
    }
  }, [svgRef, boardTopology, setScreenToViewTransform, setConnectionsAnchorX]);

  useEffect(() => {
    const pinToElement: SVGGElement[] = [];
    svgRef?.querySelectorAll("g[data-pin]").forEach((element) => {
      pinToElement[parseInt(element.getAttribute("data-pin")!)] =
        element as SVGGElement;
    });
    setPinToElement(pinToElement);

    updateSvgLayout();
  }, [svgRef, boardSvgContent, updateSvgLayout]);

  useEffect(() => {
    const resizeListener = () => {
      updateSvgLayout();
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [updateSvgLayout]);

  return {
    svgViewbox,
    screenToViewTransform,
    pinToElement,
    boardSvgContent,
    connectionsAnchorX,
    connectionsAnchorY,
  };
}

type BoundingBoxData = {
  topLeft: SVGPoint;
  bottomRight: SVGPoint;
  mid: { x: number; y: number };
};

function getNodeScreenRelativeBbox(element: SVGGElement): BoundingBoxData {
  const bbox = element.getBBox();
  const pt1 = element.ownerSVGElement!.createSVGPoint();
  const pt2 = element.ownerSVGElement!.createSVGPoint();

  pt1.x = bbox.x;
  pt1.y = bbox.y;
  pt1.w = 1;

  pt2.x = bbox.x + bbox.width;
  pt2.y = bbox.y + bbox.height;
  pt2.w = 1;

  const elementCtm = element.getCTM()!;
  const ctm = elementCtm;

  const baseRect = {
    topLeft: pt1.matrixTransform(ctm),
    bottomRight: pt2.matrixTransform(ctm),
  };

  return {
    ...baseRect,
    mid: {
      x: (baseRect.topLeft.x + baseRect.bottomRight.x) / 2,
      y: (baseRect.topLeft.y + baseRect.bottomRight.y) / 2,
    },
  };
}

const ControlIconContainer = styled.div`
  width: 1.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const OutletHeaderContainer = styled(HorizontalStack)`
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background: lightblue;
`;
